/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card,
  CardBody,
  Col,
  ButtonToolbar,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { message } from 'antd';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';
import {
  URL_REVIEW,
} from '../../../../../utils/EndPoints';

// Custom component for displaying reviewer and course
const RenderReadOnlyField = ({
  input, type, meta: { touched, error }, ...custom
}) => (
  <input {...input} {...custom} type={type} readOnly />
);

const EditReviewForm = (stateOri) => {
  const history = useHistory();
  const params = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [review, setReview] = useState(null);

  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;

  const [confirm, setConfirm] = useState(0);
  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    setConfirm(id);
    setModal(!modal);
  };

  useEffect(() => {
    initialize({
      ...data,
      reviewer: data?.user_detail?.name,
      course: data?.course_detail?.title,
    }, []);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(`${URL_REVIEW + params.id}/`, options)
      .then((res) => {
        setReview(res.data);
      }).catch(() => {
        messageApi.error('Failed to load data');
      });
  }, []);

  return (
    <Col md={12} lg={12}>
      {contextHolder}
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <form className="form">
            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">Date</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="created_at"
                    disabled
                    component="input"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Reviewer</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="reviewer"
                    component={RenderReadOnlyField}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Course</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="course"
                    component={RenderReadOnlyField}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Headline</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="headline"
                    component="input"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Rate</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="rate"
                    disabled
                    component="input"
                    type="number"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">Comment</span>
              <div className="form__form-group">
                <Field
                  name="comment"
                  disabled
                  component="textarea"
                  type="text"
                />
              </div>
            </div>

            <ButtonToolbar className="form__button-toolbar">
              <Row className="mt-1">
                <Col md={12}>
                  <button
                    type="button"
                    size="sm"
                    onClick={() => toggle(review?.id)}
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                  <Modal
                    toggle={toggle}
                    isOpen={modal}
                    backdrop={false}
                  >
                    <ModalHeader toggle={toggle}>
                      Delete Review
                    </ModalHeader>
                    <ModalBody>
                      Are you sure you want to Delete this Review?
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        onClick={() => {
                          const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                          const options = {
                            headers: {
                              'Content-Type':
                                'application/json',
                              Authorization: `Token ${token}`,
                            },
                          };
                          axios
                            .delete(
                              `${URL_REVIEW}${confirm}`,
                              options,
                            )
                            .then(() => {
                              history.push('/admin/review/');
                            })
                            .catch(() => {
                              message.error('Something went wrong!');
                            });
                        }}
                      >
                        Delete
                      </Button>
                    </ModalFooter>
                  </Modal>
                </Col>
              </Row>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'edit_review', // a unique identifier for this form
  enableReinitialize: true,
})(EditReviewForm);
