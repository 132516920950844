/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Row, Progress, Modal, ModalHeader, ModalBody, ModalFooter, Button,
} from 'reactstrap';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { message } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';
import {
  URL_FAQ,
} from '../../../../../utils/EndPoints';

const EditFAQForm = (stateOri) => {
  const history = useHistory();
  const params = useParams();

  const editorRef = useRef(null);
  const [isEditable, setEditable] = useState(false);
  const [progress, setProgress] = useState();
  const [course, setCourse] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  // loading
  const [loading, setLoading] = useState(false);

  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;
  const [confirm, setConfirm] = useState(0);
  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    setConfirm(id);
    setModal(!modal);
  };
  // Tinymce Editor
  const [content, setContents] = useState(data?.description);
  const handleEditorChange = (contents) => {
    setContents(contents);
  };

  useEffect(() => {
    initialize({
      ...data,
    });
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(`${URL_FAQ + params.id}/`, options)
      .then((res) => {
        setCourse(res.data);
        // setLoading(false);
      })
      .catch(() => {
        message.error('Something went wrong!');
        // setLoading(false);
      });
  }, []);

  return (
    <Col md={12} lg={12}>
      {contextHolder}
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Detail FAQ</h5>
          </div>
          <form
            className="form"
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              const data = new FormData();
              data.append('question', e.target.question.value);
              data.append('description', content);
              const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
              const options = {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${token}`,
                },
                onUploadProgress: (data) => {
                  setProgress(Math.round((100 * data.loaded) / data.total));
                },
              };
              axios
                .patch(`${URL_FAQ + params.id}/`, data, options)
                .then(() => {
                  history.push('/admin/faq');
                  setLoading(false);
                  messageApi.success('FAQ Successfully Updated.');
                })
                .catch(() => {
                  messageApi.error('FAQ Failed Updated');
                  setLoading(false);
                });
            }}
          >
            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">Title</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="question"
                    disabled={!isEditable}
                    component="input"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">Description</span>
              <div className="form__form-group">
                <div className="form__form-group-field w-100-role-application">
                  <input
                    id="my-file"
                    type="file"
                    name="my-file"
                    style={{ display: 'none' }}
                  />
                  <Editor
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    apiKey="kdsr6jcmxjffjg6z20pb4vtbg0fegwqdqy6ltsxoahuo3dvg"
                    initialValue={data?.description}
                    init={{
                      skin: 'snow',
                      icons: 'thin',

                      height: 500,
                      menubar: true,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen textcolor ',
                        'insertdatetime media table paste code help wordcount',
                        'image code',
                      ],
                      image_title: true,
                      automatic_uploads: true,
                      file_picker_types: 'image',
                      textcolor_rows: '4',
                      file_picker_callback(callback, value, meta) {
                        if (meta.filetype === 'image') {
                          const input = document.getElementById('my-file');
                          input.click();
                          input.onchange = function () {
                            const file = input.files[0];
                            const reader = new FileReader();
                            reader.onload = function (e) {
                              callback(e.target.result, {
                                alt: file.name,
                              });
                            };
                            reader.readAsDataURL(file);
                          };
                        }
                      },

                      content_style:
                      "@import url('http://fonts.gstatic.com/s/nunito/v7/1TiHc9yag0wq3lDO9cw0vpBw1xU1rKptJj_0jans920.woff2');",
                      font_formats: 'Nunito',

                      toolbar:
                      'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
                    }}
                    onEditorChange={handleEditorChange}
                    outputFormat="html"
                  />
                </div>
              </div>
            </div>
            {loading && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}
            <ButtonToolbar className="form__button-toolbar">
              <Row className="mt-4">
                <Col md={12}>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setEditable(!isEditable)}
                    color={isEditable ? 'danger' : 'primary'}
                  >
                    {' '}
                    {!isEditable ? 'Change' : 'Cancel'}
                  </button>
                  {isEditable && (
                    <button
                      type="submit"
                      className="btn btn-primary"
                    >
                      {' '}
                      Save
                    </button>
                  )}
                  {isEditable === false && (
                    <button
                      type="button"
                      size="sm"
                      onClick={() => toggle(course?.id)}
                      className="btn btn-danger"
                    >
                      Delete
                    </button>
                  )}
                  <Modal
                    toggle={toggle}
                    isOpen={modal}
                    backdrop={false}
                  >
                    <ModalHeader toggle={toggle}>
                      Delete FAQ
                    </ModalHeader>
                    <ModalBody>
                      Are you sure you want to Delete this item?
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        onClick={() => {
                          const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                          const options = {
                            headers: {
                              'Content-Type':
                                'application/json',
                              Authorization: `Token ${token}`,
                            },
                          };
                          axios
                            .delete(
                              `${URL_FAQ}${confirm}`,
                              options,
                            )
                            .then(() => {
                              history.push('/admin/faq/');
                            })
                            .catch(() => {
                              message.error('Something went wrong!');
                            });
                        }}
                      >
                        Delete
                      </Button>
                    </ModalFooter>
                  </Modal>
                </Col>
              </Row>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

// EditFAQForm.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
// };

export default reduxForm({
  form: 'edit_course', // a unique identifier for this form
  enableReinitialize: true,
})(EditFAQForm);
