/* eslint-disable indent */
/* eslint-disable no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container, Row,
} from 'reactstrap';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/Types';
import { URL_INSTRUCTOR } from '../../../../utils/EndPoints';
import TeacherListTable from './components/teacherList';
import ListDataTeacher from './components/listDataTeacher';

const TeacherList = () => {
  const [teacher, setTeacher] = useState([]);
  const listDataTable = ListDataTeacher(teacher);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(`${URL_INSTRUCTOR}`, options)
      .then((res) => {
        setTeacher(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <TeacherListTable
          listDataTable={listDataTable}
          isLoading={isLoading}
        />
      </Row>
    </Container>
  );
};
export default TeacherList;
