/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const IntructorEdit = (history, info) => {
  history.push({
    pathname: `/admin/instructor-edit/${info.id}`,
    state: { data: info },
  });
};

const ListDataInstructorList = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'Number',
        accessor: 'number',
        width: 20,
        disableSortBy: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Position',
        accessor: 'position',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    for (let i = 0; i < dataAPI.length; i += 1) {
      data.push({
        id: dataAPI[i].id,
        number: `${i + 1}`,
        name: dataAPI[i]?.instructor_detail?.name ?? '-',
        position: dataAPI[i]?.instructor_detail?.position ?? '-',
        action: [
          // eslint-disable-next-line no-loop-func
          // eslint-disable-next-line max-len
          (<Button key={Math.random()} color="outline-success" onClick={() => IntructorEdit(history, dataAPI[i])}>Detail</Button>),
        ],
      });
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataInstructorList;
