/* eslint-disable max-len */
import React, { useState } from 'react';
import axios from 'axios';
import { message } from 'antd';
import ResetPasswordForm from './components/resetPasswordForm';
import { URL_RESET_PASSWORD } from '../../../utils/EndPoints';

const ResetPassword = () => {
  const [isLoading, setLoading] = useState(false);

  const onSubmit = (e) => {
    const data = new FormData();
    data.append('email', e.email);
    setLoading(true);
    axios.post(`${URL_RESET_PASSWORD}`, data)
      .then(() => {
        setLoading(false);
        message.success('Link sent to your email. Please check inbox or spam.');
      })
      .catch(() => {
        setLoading(false);
        message.error('Email not found.');
      });
  };

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Forgot Password</h1>
          <p>Input Your Email and Regist to Asean Center Energy System. We will help for Reset Password.</p>
          <div className="login-form" style={{ marginBottom: '20px' }}>
            {/*  */}
          </div>
          {isLoading}
          <ResetPasswordForm onSubmit={(e) => onSubmit(e)} />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
