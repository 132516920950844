/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card,
  CardBody,
  Col,
  ButtonToolbar,
  Row,
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { Spin, message } from 'antd';
import { LOCALSTORAGE_TOKEN, LOCALSTORAGE_USERDETAIL } from '../../../../../utils/Types';
import {
  URL_COURSE,
  URL_WISHLISTSTATS, URL_USERS,
} from '../../../../../utils/EndPoints';
import renderSelectField from '../../../../../shared/components/form/Select';

const EditWishlistForm = (stateOri) => {
  const history = useHistory();
  const params = useParams();

  const [isEditable, setEditable] = useState(false);
  const [progress, setProgress] = useState();
  const [wishlist, setWishlist] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  // list select option
  const [course, setCourse] = useState([]);
  const [user, setUser] = useState([]);

  // loading
  const [loading, setLoading] = useState(false);

  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;
  const [confirm, setConfirm] = useState(0);
  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    setConfirm(id);
    setModal(!modal);
  };

  useEffect(() => {
    initialize({
      ...data,
      course: { value: `${data?.courseId}`, label: data?.course_detail?.title },
      user: { value: `${data?.userId}`, label: data?.user_detail?.name },
    }, []);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(`${URL_WISHLISTSTATS + params.id}/`, options)
      .then((res) => {
        setWishlist(res.data);
        // setLoading(false);
      })
      .catch(() => {
        message.error('Something went wrong!');
        // setLoading(false);
      });
    setIsLoading(true);
    setIsLoading1(true);
    axios
      .get(`${URL_COURSE}/`, options)
      .then((res) => {
        setCourse(
          res.data.map((course) => ({
            value: `${course.id}`,
            label: course.title,
          })),
        );
        setIsLoading(false);
      })
      .catch(() => {
        message.error('Something went wrong!');
        setIsLoading(false);
      });
    axios
      .get(`${URL_USERS}/`, options)
      .then((res1) => {
        setUser(
          res1.data.map((user) => ({
            value: `${user.id}`,
            label: user.name,
          })),
        );
        setIsLoading1(false);
      })
      .catch(() => {
        message.error('Something went wrong!');
        setIsLoading1(false);
      });
  }, []);

  return (
    <Col md={12} lg={12}>
      {contextHolder}
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <form
            className="form"
            onSubmit={(e) => {
              e.preventDefault();
              const userDetail = JSON.parse(
                localStorage.getItem(LOCALSTORAGE_USERDETAIL),
              );
              setLoading(true);
              const data = new FormData();
              data.append('userId', userDetail.id);
              data.append('title', e.target.title.value);
              data.append('courseId', e.target.course.value);
              data.append('userId', e.target.user.value);
              const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
              const options = {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${token}`,
                },
                onUploadProgress: (data) => {
                  setProgress(Math.round((100 * data.loaded) / data.total));
                },
              };
              axios
                .patch(`${URL_WISHLISTSTATS + params.id}/`, data, options)
                .then(() => {
                  messageApi.success('Wishlist Failed Updated');
                  history.push('/admin/wishlist');
                  setLoading(false);
                })
                .catch(() => {
                  messageApi.error('Wishlist Failed Updated');
                  setLoading(false);
                });
            }}
          >
            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">Date</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="created_at"
                    disabled
                    component="input"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Course {isLoading ? <Spin /> : null}</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="course"
                    component={renderSelectField}
                    options={course}
                    type="text"
                    placeholder="Select Course"
                    required
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">User {isLoading1 ? <Spin /> : null}</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="user"
                    component={renderSelectField}
                    options={user}
                    type="text"
                    placeholder="Select User"
                    required
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div>

            {loading && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}
            <ButtonToolbar className="form__button-toolbar">
              <Row className="mt-4">
                <Col md={12}>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setEditable(!isEditable)}
                    color={isEditable ? 'danger' : 'primary'}
                  >
                    {' '}
                    {!isEditable ? 'Change' : 'Cancel'}
                  </button>
                  {isEditable && (
                    <button
                      type="submit"
                      className="btn btn-primary"
                    >
                      {' '}
                      Save
                    </button>
                  )}
                  {isEditable === false && (
                    <button
                      type="button"
                      size="sm"
                      onClick={() => toggle(wishlist?.id)}
                      className="btn btn-danger"
                    >
                      Delete
                    </button>
                  )}
                  <Modal
                    toggle={toggle}
                    isOpen={modal}
                    backdrop={false}
                  >
                    <ModalHeader toggle={toggle}>
                      Delete Wishlist
                    </ModalHeader>
                    <ModalBody>
                      Are you sure you want to Delete this Wishlist?
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        onClick={() => {
                          const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                          const options = {
                            headers: {
                              'Content-Type':
                                'application/json',
                              Authorization: `Token ${token}`,
                            },
                          };
                          axios
                            .delete(
                              `${URL_WISHLISTSTATS}${confirm}`,
                              options,
                            )
                            .then(() => {
                              history.push('/admin/wishlist/');
                            })
                            .catch(() => {
                              message.error('Something went wrong!');
                            });
                        }}
                      >
                        Delete
                      </Button>
                    </ModalFooter>
                  </Modal>
                </Col>
              </Row>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'edit_wishlist', // a unique identifier for this form
  enableReinitialize: true,
})(EditWishlistForm);
