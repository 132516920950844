/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Progress, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { message } from 'antd';
import {
  LOCALSTORAGE_TOKEN,
} from '../../../../../utils/Types';
import renderFileInputField from '../../../../../shared/components/form/FileInput';
import {
  URL_PARTNERSHIP,
} from '../../../../../utils/EndPoints';

const EditPartnerForm = (stateOri) => {
  const history = useHistory();
  const params = useParams();
  const [progress, setProgress] = useState(null);
  const [isEditable, setEditable] = useState(false);
  const [image, setImage] = useState(null);
  const { location } = history;
  const { state } = location;
  const { initialize } = stateOri;
  const { data } = state;
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    initialize(data);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    if (e.target.image.files[0]) {
      data.append('image', e.target.image.files[0]);
    }
    data.append('name', e.target.name.value);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };
    axios
      .patch(`${URL_PARTNERSHIP + params.id}/`, data, options)
      .then(() => {
        messageApi.success('Partners Successfully Updated');
        history.push('/admin/partners');
      })
      .catch(() => {
        messageApi.error('Partners Failed Updated');
      });
  };

  const imageHandler = () => {
    if (image) {
      return image;
    } if (data.image) {
      return data.image;
    }
    return '/img/no_image.png';
  };

  const inputImageHandler = (e) => {
    setImage(URL.createObjectURL(e.file));
    const conditions = ['image/png', 'image/jpg', 'image/jpeg'];

    if (!conditions.some((el) => e.file.type.includes(el))) {
      messageApi.open({
        type: 'error',
        content: 'Type file image only',
      });
    }
  };

  return (
    <Col md={12} lg={12}>
      {contextHolder}
      <ButtonToolbar className="form__button-toolbar">
        <Button size="sm" color="danger" onClick={history.goBack}>
          Back
        </Button>
      </ButtonToolbar>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Detail Partner</h5>
          </div>
          <form className="form" onSubmit={onSubmit}>
            <img src={imageHandler()} alt="" className="testimonial-image" />
            <span className="form__form-group-label">Image</span>
            <div className="form__form-group">
              <div className="form__form-group-field mb-0-label">
                <Field
                  name="image"
                  component={renderFileInputField}
                  required
                  onChange={(e) => { inputImageHandler(e); }}
                />
              </div>
            </div>
            <span className="form__form-group-label">Name</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component="input"
                  type="text"
                  placeholder="Name"
                  required
                  disabled={!isEditable}
                />
              </div>
            </div>
            <span className="form__form-group-label">Date</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="created_at"
                  component="input"
                  type="text"
                  placeholder="Date"
                  disabled
                />
              </div>
            </div>

            {progress !== null && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setEditable(!isEditable)}
                color={isEditable ? 'danger' : 'primary'}
              >
                {' '}
                {!isEditable ? 'Change' : 'Cancel'}
              </button>
              {isEditable && (
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  {' '}
                  Save
                </button>
              )}
              {isEditable === false && (
                <button
                  type="button"
                  size="sm"
                  onClick={() => toggle()}
                  className="btn btn-danger"
                >
                  Delete
                </button>
              )}
              <Modal
                toggle={toggle}
                isOpen={modal}
                backdrop={false}
              >
                <ModalHeader toggle={toggle}>
                  Delete Partner
                </ModalHeader>
                <ModalBody>
                  Are you sure you want to Delete this Partner?
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                      const options = {
                        headers: {
                          'Content-Type':
                            'application/json',
                          Authorization: `Token ${token}`,
                        },
                      };
                      axios
                        .delete(
                          `${URL_PARTNERSHIP}${data.id}`,
                          options,
                        )
                        .then(() => {
                          history.push('/admin/partners/');
                        })
                        .catch(() => {
                          message.error('Something went wrong!');
                        });
                    }}
                  >
                    Delete
                  </Button>
                </ModalFooter>
              </Modal>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'EditPartner_form', // a unique identifier for this form
})(EditPartnerForm);
