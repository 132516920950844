/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Progress,
} from 'reactstrap';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import {
  LOCALSTORAGE_TOKEN,
} from '../../../../../utils/Types';
import renderFileInputField from '../../../../../shared/components/form/FileInput';
import { URL_USERS } from '../../../../../utils/EndPoints';
import renderSelectField from '../../../../../shared/components/form/Select';

const CreateUserForm = () => {
  const history = useHistory();
  const [progress, setProgress] = useState(null);
  const [photo, setphoto] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedLabel, setSelectedLabel] = useState();

  const countries = [
    { value: 'BN', label: 'Brunei Darussalam' },
    { value: 'KH', label: 'Cambodia' },
    { value: 'ID', label: 'Indonesia' },
    { value: 'LA', label: 'Laos' },
    { value: 'MY', label: 'Malaysia' },
    { value: 'MM', label: 'Myanmar' },
    { value: 'PH', label: 'Philippines' },
    { value: 'SG', label: 'Singapore' },
    { value: 'TH', label: 'Thailand' },
    { value: 'VN', label: 'VietNam' },
  ];

  const handleChange = (event) => {
    const selectedOption = countries.find(
      (option) => option.value === event.value,
    );
    setSelectedLabel(selectedOption.label);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    if (e.target.photo.files[0]) {
      data.append('photo', e.target.photo.files[0]);
    }
    data.append('name', e.target.name.value);
    data.append('email', e.target.email.value);
    data.append('password', e.target.password.value);
    data.append('role', e.target.role.value);
    data.append('phone', e.target.phone.value);
    data.append('job', e.target.job.value);
    data.append('street_address', e.target.street_address.value);
    data.append('city', e.target.city.value);
    data.append('country', selectedLabel);
    data.append('country_code', e.target.country.value);
    data.append('postal_code', e.target.postal_code.value);
    data.append('organization', e.target.organization.value);
    data.append('about', e.target.about.value);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };
    axios
      .post(URL_USERS, data, options)
      .then(() => {
        messageApi.success('New User Successfully Created');
        history.push('/admin/user');
      }).catch(() => {
        messageApi.error('New User Failed Created');
      });
  };

  const imageHandler = () => {
    if (photo) {
      return photo;
    }
    return '/img/no_image.png';
  };

  const inputImageHandler = (e) => {
    setphoto(URL.createObjectURL(e.file));
    const conditions = ['image/png', 'image/jpg', 'image/jpeg'];

    if (!conditions.some((el) => e.file.type.includes(el))) {
      messageApi.open({
        type: 'error',
        content: 'Only image file types allowed',
      });
    }
  };

  return (
    <Col md={12} lg={12}>
      {contextHolder}
      <ButtonToolbar className="form__button-toolbar">
        <Button size="sm" color="danger" onClick={history.goBack}>
          Back
        </Button>
      </ButtonToolbar>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <form className="form" onSubmit={onSubmit}>
            <div className="col-lg-12 col-md-12">
              <img src={imageHandler()} alt="" className="testimonial-image" />
              <span className="form__form-group-label">Photo</span>
              <div className="form__form-group">
                <div className="form__form-group-field mb-0-label">
                  <Field
                    name="photo"
                    component={renderFileInputField}
                    required
                    onChange={(e) => { inputImageHandler(e); }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Full Name</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="name"
                    component="input"
                    type="text"
                    placeholder="Name"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Email</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="email"
                    component="input"
                    type="email"
                    placeholder="Enter Email"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <span className="form__form-group-label">Phone</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="phone"
                    component="input"
                    type="text"
                    placeholder="Enter Phone Number"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <span className="form__form-group-label">Role</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="role"
                    component={renderSelectField}
                    type="text"
                    options={[
                      { value: 'User', label: 'User' },
                      { value: 'Admin', label: 'Admin' },
                    ]}
                    placeholder="Select Role"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <span className="form__form-group-label">Password</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="password"
                    component="input"
                    type="password"
                    placeholder="Enter password"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Job</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="job"
                    component="input"
                    type="text"
                    placeholder="Enter Job"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Organization</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="organization"
                    component="input"
                    type="text"
                    placeholder="Enter Organization"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">Street Address</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="street_address"
                    component="textarea"
                    type="text"
                    placeholder="Enter Street Address"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <span className="form__form-group-label">City</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="city"
                    component="input"
                    type="text"
                    placeholder="Enter City"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <span className="form__form-group-label">Country</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="country"
                    component={renderSelectField}
                    type="text"
                    options={countries}
                    placeholder="Select Country"
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <span className="form__form-group-label">Postal Code</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="postal_code"
                    component="input"
                    type="text"
                    placeholder="Enter Postal Code"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">About</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="about"
                    component="textarea"
                    type="text"
                    placeholder="Enter Intructor Personal Experince"
                    required
                  />
                </div>
              </div>
            </div>

            {progress !== null && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                type="submit"
              >
                Submit
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'CreateUser_form', // a unique identifier for this form
})(CreateUserForm);
