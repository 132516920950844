/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonToolbar,
  Card, CardBody, Col,
} from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import EditInstructorForm from './components/editInstructorForm';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';
import axios from 'axios';
import { URL_INSTRUCTOR } from '../../../../../utils/EndPoints';
import { message } from 'antd';

const EditInstructor = () => {
  const history = useHistory();
  const params = useParams();
  const [instructor, setInstructor] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(`${URL_INSTRUCTOR}${params.id}`, options)
      .then((res) => {
        setInstructor(res.data);
      }).catch(() => {
        message.error('Something went wrong!');
      });
  }, []);
  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <ButtonToolbar className="form__button-toolbar">
          <Button size="sm" color="danger" onClick={history.goBack}>
            Back
          </Button>
        </ButtonToolbar>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">
              Update {instructor?.user_detail?.name}
            </h5>
          </div>
          <div className="tabs tabs--justify tabs--bordered-bottom">
            <EditInstructorForm />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default EditInstructor;
