/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, Table,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';

const EditOrderForm = (stateOri) => {
  const history = useHistory();

  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;

  useEffect(() => {
    initialize({
      ...data,
      name: data?.user_detail?.name,
      status: data?.status,
    });
  }, []);

  return (
    <Col md={12} lg={12}>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">

          <Table striped>
            <tbody>
              <tr>
                <th scope="row">Name</th>
                <td>{data?.user_detail?.name}</td>
              </tr>
              <tr>
                <th scope="row">Order</th>
                <td>{data?.paypal_order_id}</td>
              </tr>
              <tr>
                <th scope="row">Course</th>
                <td>
                  {data?.item_list && data.item_list.map((item, index) => (
                    <div key={item.id}>{`${index + 1}. ${item.course_detail?.title}`}</div>
                  ))}
                </td>
              </tr>
            </tbody>
          </Table><br />
          <form className="form">
            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">User</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="name"
                    component="input"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Paypal Order ID</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="paypal_order_id"
                    disabled
                    component="input"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <span className="form__form-group-label">Date</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="created_at"
                    disabled
                    component="input"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <span className="form__form-group-label">Order ID</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="order_id"
                    disabled
                    component="input"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <span className="form__form-group-label">Total Price ($)</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="total_price"
                    disabled
                    component="input"
                    type="number"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <span className="form__form-group-label">Status</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="status"
                    component="input"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">Payment URL</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="payment_url"
                    disabled
                    component="input"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'edit_order', // a unique identifier for this form
  enableReinitialize: true,
})(EditOrderForm);
