/* eslint-disable indent */
/* eslint-disable no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container, Row,
} from 'reactstrap';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/Types';
import { URL_REVIEW } from '../../../../utils/EndPoints';
import ListDataReview from './components/ListData';
import ReviewListingTable from './components/ReviewListing';

const ReviewListing = () => {
  const [enrollment, setEnrollment] = useState([]);
  const listDataTable = ListDataReview(enrollment);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(URL_REVIEW, options)
      .then((res) => {
        setEnrollment(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <ReviewListingTable
          listDataTable={listDataTable}
          isLoading={isLoading}
        />
      </Row>
    </Container>
  );
};
export default ReviewListing;
