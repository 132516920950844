/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress,
} from 'reactstrap';
import { message } from 'antd';
import { URL_TOPIC } from '../../../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../../../utils/Types';

const EditTopicForm = (stateOri) => {
  const history = useHistory();
  const { id } = useParams();
  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const [isEditable, setEditable] = useState(false);
  const [topic, setTopic] = useState([]);
  const [confirm, setConfirm] = useState(0);
  const [modal, setModal] = useState(false);
  const toggle = (idTopic) => {
    setConfirm(idTopic);
    setModal(!modal);
  };
  useEffect(() => {
    initialize(data);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };

    axios
      .get(`${URL_TOPIC}${id}/`, options)
      .then((res) => {
        setTopic(res.data);
      })
      .catch(() => {
        message.error('Something went wrong!');
      });
  }, []);

  const onSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const dataEdit = new FormData();
    dataEdit.append('order', e.target.order.value);
    dataEdit.append('title', e.target.title.value);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: () => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };
    axios.patch(`${URL_TOPIC}${id}/`, dataEdit, options)
      .then(() => {
        messageApi.success('Curriculum Successfully Updated');
        history.push({
          pathname: `/admin/course-curriculum/${data.courseId}`,
          search: '?tabs=1',
        });
        setLoading(false);
      })
      .catch(() => {
        messageApi.error('Curriculum Failed Updated');
        setLoading(false);
      });
  };
  return (
    <Col md={12} lg={12}>
      {contextHolder}
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <form className="form" onSubmit={onSubmit}>
            <span className="form__form-group-label">Curriculum Order
            </span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="order"
                  disabled={!isEditable}
                  component="input"
                  type="number"
                  required
                />
              </div>
            </div>
            <span className="form__form-group-label">Curriculum Title
            </span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="title"
                  disabled={!isEditable}
                  component="input"
                  type="text"
                  required
                />
              </div>
            </div>

            {loading && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setEditable(!isEditable)}
                color={isEditable ? 'danger' : 'primary'}
              >
                {!isEditable ? 'Change' : 'Cancel'}
              </button>
              {isEditable && (
              <button
                type="submit"
                className="btn btn-primary"
              >
                {' '}
                Save
              </button>
              )}
              {isEditable === false && (
              <button
                type="button"
                size="sm"
                onClick={() => toggle(topic?.id)}
                className="btn btn-danger"
              >
                Delete
              </button>
              )}
              <Modal
                toggle={toggle}
                isOpen={modal}
                backdrop={false}
              >
                <ModalHeader toggle={toggle}>
                  Delete Curriculum?
                </ModalHeader>
                <ModalBody>
                  Are you sure you want to Delete this Curriculum?
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                      const options = {
                        headers: {
                          'Content-Type':
                            'application/json',
                          Authorization: `Token ${token}`,
                        },
                      };
                      axios
                        .delete(
                          `${URL_TOPIC}${confirm}`,
                          options,
                        )
                        .then(() => {
                          history.push({
                            pathname: `/admin/course-curriculum/${topic.courseId}`,
                            search: '?tabs=1',
                          });
                        })
                        .catch(() => {
                          message.error('Something went wrong!');
                        });
                    }}
                  >
                    Delete
                  </Button>
                </ModalFooter>
              </Modal>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'edit_topic', // a unique identifier for this form
  enableReinitialize: true,
})(EditTopicForm);
