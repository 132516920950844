/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Row, Progress, Modal, ModalHeader, ModalBody, ModalFooter, Button,
} from 'reactstrap';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { message } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';
import renderCheckBoxField from '../../../../../shared/components/form/CheckBox';
import renderFileInputField from '../../../../../shared/components/form/FileInput';
import {
  URL_CATEGORYCOURSE,
  URL_COURSE,
  URL_SUBCATEGORYCOURSEBYCATEGORY,
} from '../../../../../utils/EndPoints';
import renderSelectField from '../../../../../shared/components/form/Select';

const EditCourseForm = (stateOri) => {
  const history = useHistory();
  const params = useParams();

  const editorRef = useRef(null);
  const [isEditable, setEditable] = useState(false);
  const [progress, setProgress] = useState();
  const [course, setCourse] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  // list select option
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  // loading
  const [loading, setLoading] = useState(false);
  const [categoriesImg, setCategoriesImg] = useState(null);
  const [coursesImg, setCoursesImg] = useState(null);
  const [coursesSmallImg, setCoursesSmallImg] = useState(null);
  const [coursePreview, setCoursePreview] = useState(null);

  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;
  const [confirm, setConfirm] = useState(0);
  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    setConfirm(id);
    setModal(!modal);
  };
  // const [isPublishChecked, setIsPublishChecked] = useState(false);
  // Tinymce Editor
  const [content, setContents] = useState(data?.overview);
  const handleEditorChange = (contents) => {
    setContents(contents);
  };

  useEffect(() => {
    initialize({
      ...data,
      category: { value: `${data?.categoryId}`, label: data?.category_detail?.name },
      type: { value: `${data?.typeId}`, label: data?.type_detail?.name },
    });
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(`${URL_COURSE + params.id}/`, options)
      .then((res) => {
        setCourse(res.data);
        // setLoading(false);
      })
      .catch(() => {
        // setLoading(false);
        message.error('Something went wrong!');
      });
    axios
      .get(URL_CATEGORYCOURSE, options)
      .then((res) => {
        setCategories(
          res.data.map((category) => ({
            value: `${category.id}`,
            label: category.name,
          })),
        );
      })
      .catch(() => {
        message.error('Something went wrong!');
      });
    axios
      .get(
        `${URL_SUBCATEGORYCOURSEBYCATEGORY}`,
        options,
      )
      .then((res1) => {
        setSubcategories(
          res1.data.map((category) => ({
            value: `${category.id}`,
            label: category.name,
          })),
        );
      })
      .catch(() => {
        message.error('Something went wrong!');
      });
  }, []);

  const categoriesImageHandler = () => {
    if (categoriesImg) {
      return categoriesImg;
    } if (data.categories_img) {
      return data.categories_img;
    }
    return '/img/no_image.png';
  };
  const coursesImageHandler = () => {
    if (coursesImg) {
      return coursesImg;
    } if (data.courses_img) {
      return data.courses_img;
    }
    return '/img/no_image.png';
  };

  const coursesSmallImageHandler = () => {
    if (coursesSmallImg) {
      return coursesSmallImg;
    } if (data.courses_small_img) {
      return data.courses_small_img;
    }
    return '/img/no_image.png';
  };
  const coursesVideoPreviewHandler = () => {
    if (coursePreview) {
      return coursePreview;
    } if (data?.course_preview_video) {
      return data?.course_preview_video;
    }
    return '/img/no_image.png';
  };

  const inputCategoriesImageHandler = (e) => {
    setCategoriesImg(URL.createObjectURL(e.file));
    const conditions = ['image/png', 'image/jpg', 'image/jpeg'];

    if (!conditions.some((el) => e.file.type.includes(el))) {
      messageApi.open({
        type: 'error',
        content: 'Type file image only',
      });
    }
  };
  const inputCoursesImageHandler = (e) => {
    setCoursesImg(URL.createObjectURL(e.file));
    const conditions = ['image/png', 'image/jpg', 'image/jpeg'];

    if (!conditions.some((el) => e.file.type.includes(el))) {
      messageApi.open({
        type: 'error',
        content: 'Type file image only',
      });
    }
  };
  const inputCoursesSmallImageHandler = (e) => {
    setCoursesSmallImg(URL.createObjectURL(e.file));
    const conditions = ['image/png', 'image/jpg', 'image/jpeg'];

    if (!conditions.some((el) => e.file.type.includes(el))) {
      messageApi.open({
        type: 'error',
        content: 'Type file image only',
      });
    }
  };
  const inputVideoPreviewHandler = (e) => {
    setCoursePreview(URL.createObjectURL(e.file));
    if (!e.file.type.includes('video')) {
      messageApi.open({
        type: 'error',
        content: 'Fill with video type file',
      });
    }
  };

  return (
    <Col md={12} lg={12}>
      {contextHolder}
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Detail Course</h5>
          </div>
          <form
            className="form"
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              const data = new FormData();
              data.append('title', e.target.title.value);
              // data.append('duration', e.target.duration.value);
              // data.append('lesson_hours', e.target.lesson_hours.value);
              data.append('price', e.target.price.value);
              if (e.target.categories_img.files[0]) {
                data.append('categories_img', e.target.categories_img.files[0]);
              }
              if (e.target.courses_img.files[0]) {
                data.append('courses_img', e.target.courses_img.files[0]);
              }
              if (e.target.courses_small_img.files[0]) {
                data.append(
                  'courses_small_img',
                  e.target.courses_small_img.files[0],
                );
              }
              if (e.target.course_preview_video.files[0]) {
                data.append(
                  'course_preview_video',
                  e.target.course_preview_video.files[0],
                );
              }
              if (e.target.category.value) {
                data.append('categoryId', e.target.category.value);
              }
              if (e.target.type.value) {
                data.append('typeId', e.target.type.value);
              }
              data.append('overview', content);
              data.append('course_code', e.target.course_code.value);
              data.append('isPublish', e.target.isPublish.checked);
              const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
              const options = {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${token}`,
                },
                onUploadProgress: (data) => {
                  setProgress(Math.round((100 * data.loaded) / data.total));
                },
              };
              axios
                .patch(`${URL_COURSE + params.id}/`, data, options)
                .then(() => {
                  messageApi.success('Course Successfully Updated');
                  history.push('/admin/course');
                  setLoading(false);
                })
                .catch(() => {
                  messageApi.error('Course Failed Updated');
                  setLoading(false);
                });
            }}
          >
            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">Date</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="created_at"
                    disabled
                    component="input"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">Title</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="title"
                    disabled={!isEditable}
                    component="input"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Topic</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="category"
                    component={renderSelectField}
                    type="text"
                    options={categories}
                    placeholder="Topic"
                    required
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <span className="form__form-group-label">Category</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="type"
                    component={renderSelectField}
                    type="text"
                    options={subcategories}
                    placeholder="Category"
                    required
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <span className="form__form-group-label">Course Code</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="course_code"
                    component="input"
                    type="text"
                    placeholder="Course Code, example: 1AB"
                    required
                    disabled={!isEditable}
                    maxLength={2}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <img src={categoriesImageHandler()} alt="" className="testimonial-image" />
              <span className="form__form-group-label">Category Image (750 x 500)</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="categories_img"
                    component={renderFileInputField}
                    accept="image/x-png,image/jpeg"
                    onChange={(e) => { inputCategoriesImageHandler(e); }}
                  />
                </div>
                <p>Current Link: {course !== null ? (
                  <a href={course.categories_img} target="_blank" rel="noreferrer">
                    {course.categories_img}
                  </a>
                ) : '-'}
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <img src={coursesImageHandler()} alt="" className="testimonial-image" />
              <span className="form__form-group-label">Course Image (1920 x 500)</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="courses_img"
                    component={renderFileInputField}
                    onChange={(e) => { inputCoursesImageHandler(e); }}
                  />
                </div>
                <p>Current Link: {course !== null ? (
                  <a href={course.courses_img} target="_blank" rel="noreferrer">
                    {course.courses_img}
                  </a>
                ) : '-'}
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <img src={coursesSmallImageHandler()} alt="" className="testimonial-image" />
              <span className="form__form-group-label">Small Course Image (380 X 380)</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="courses_small_img"
                    component={renderFileInputField}
                    onChange={(e) => { inputCoursesSmallImageHandler(e); }}
                  />
                </div>
                <p>Current Link: {course !== null ? (
                  <a href={course.courses_small_img} target="_blank" rel="noreferrer">
                    {course.courses_small_img}
                  </a>
                ) : '-'}
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <iframe
                className="testimonial-image"
                width="420"
                height="280"
                src={coursesVideoPreviewHandler()}
              />
              <span className="form__form-group-label">Video Preview</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="course_preview_video"
                    component={renderFileInputField}
                    onChange={(e) => {
                      inputVideoPreviewHandler(e);
                    }}
                  />
                </div>
                <p>Current Link: {course !== null ? (
                  <a href={course.course_preview_video} target="_blank" rel="noreferrer">
                    {course.course_preview_video}
                  </a>
                ) : '-'}
                </p>
              </div>
            </div>
            {/* <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Duration (Minute)</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="duration"
                    component="input"
                    type="text"
                    placeholder="Duration Minute (ex: 120)"
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Learning Hours (Hours)</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="lesson_hours"
                    component="input"
                    type="text"
                    placeholder="Learning Hours (ex: 10)"
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div> */}
            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">Price (Dollar)</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="price"
                    component="input"
                    min={0}
                    type="number"
                    placeholder="Price (ex: 50)"
                    required
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">Description</span>
              <div className="form__form-group">
                <div className="form__form-group-field w-100-role-application">
                  <input
                    id="my-file"
                    type="file"
                    name="my-file"
                    style={{ display: 'none' }}
                  />
                  <Editor
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    apiKey="kdsr6jcmxjffjg6z20pb4vtbg0fegwqdqy6ltsxoahuo3dvg"
                    initialValue={data.overview}
                    init={{
                      skin: 'snow',
                      icons: 'thin',

                      height: 500,
                      menubar: true,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen textcolor ',
                        'insertdatetime media table paste code help wordcount',
                        'image code',
                      ],
                      image_title: true,
                      automatic_uploads: true,
                      file_picker_types: 'image',
                      textcolor_rows: '4',
                      file_picker_callback(callback, value, meta) {
                        if (meta.filetype === 'image') {
                          const input = document.getElementById('my-file');
                          input.click();
                          input.onchange = function () {
                            const file = input.files[0];
                            const reader = new FileReader();
                            reader.onload = function (e) {
                              callback(e.target.result, {
                                alt: file.name,
                              });
                            };
                            reader.readAsDataURL(file);
                          };
                        }
                      },

                      content_style:
                      "@import url('http://fonts.gstatic.com/s/nunito/v7/1TiHc9yag0wq3lDO9cw0vpBw1xU1rKptJj_0jans920.woff2');",
                      font_formats: 'Nunito',

                      toolbar:
                      'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
                    }}
                    onEditorChange={handleEditorChange}
                    outputFormat="html"
                  />
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="isPublish"
                  component={renderCheckBoxField}
                  disabled={!isEditable}
                  label="Is Course ready to be released?"
                />
              </div>
            </div>
            {loading && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}
            <ButtonToolbar className="form__button-toolbar">
              <Row className="mt-4">
                <Col md={12}>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setEditable(!isEditable)}
                    color={isEditable ? 'danger' : 'primary'}
                  >
                    {' '}
                    {!isEditable ? 'Change' : 'Cancel'}
                  </button>
                  {isEditable && (
                    <button
                      type="submit"
                      className="btn btn-primary"
                    >
                      {' '}
                      Save
                    </button>
                  )}
                  {isEditable === false && (
                    <button
                      type="button"
                      size="sm"
                      onClick={() => toggle(course?.id)}
                      className="btn btn-danger"
                    >
                      Delete
                    </button>
                  )}
                  <Modal
                    toggle={toggle}
                    isOpen={modal}
                    backdrop={false}
                  >
                    <ModalHeader toggle={toggle}>
                      Delete Course
                    </ModalHeader>
                    <ModalBody>
                      Are you sure you want to Delete this Course?
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        onClick={() => {
                          const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                          const options = {
                            headers: {
                              'Content-Type':
                                'application/json',
                              Authorization: `Token ${token}`,
                            },
                          };
                          axios
                            .delete(
                              `${URL_COURSE}${confirm}`,
                              options,
                            )
                            .then(() => {
                              history.push('/admin/course/');
                            })
                            .catch(() => {
                              message.error('Something went wrong!');
                            });
                        }}
                      >
                        Delete
                      </Button>
                    </ModalFooter>
                  </Modal>
                </Col>
              </Row>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

// EditCourseForm.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
// };

export default reduxForm({
  form: 'edit_course', // a unique identifier for this form
  enableReinitialize: true,
})(EditCourseForm);
