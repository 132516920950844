/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress, Row,
} from 'reactstrap';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Editor } from '@tinymce/tinymce-react';
import { message } from 'antd';
import { URL_MATERIAL } from '../../../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN, LOCALSTORAGE_USERDETAIL } from '../../../../../../utils/Types';
import renderFileInputField from '../../../../../../shared/components/form/FileInput';

const EditMaterialForm = (stateOri) => {
  const history = useHistory();
  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;

  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState();

  // alert
  const [alertModul, setAlertModul] = useState(false);
  const [alertVideo, setAlertVideo] = useState(false);
  const [alertTranscript, setAlertFileTranscript] = useState(false);

  const [isEditable, setEditable] = useState(false);
  const [material, setMaterial] = useState(null);

  const [confirm, setConfirm] = useState(0);
  const [modal, setModal] = useState(false);
  const toggle = (idMaterial) => {
    setConfirm(idMaterial);
    setModal(!modal);
  };
  const editorRef = useRef(null);
  // Tinymce Editor
  const [content, setContents] = useState(data?.transcript);
  const handleEditorChange = (contents) => {
    setContents(contents);
  };

  useEffect(() => {
    initialize(data);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(`${URL_MATERIAL + id}/`, options)
      .then((res) => {
        setMaterial(res.data);
      })
      .catch(() => {
        message.error('Something went wrong!');
      });
  }, []);

  return (
    <Col md={12} lg={12}>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <form
            className="form"
            onSubmit={(e) => {
              e.preventDefault();
              const userDetail = JSON.parse(localStorage.getItem(LOCALSTORAGE_USERDETAIL));
              const dataEdit = new FormData();
              dataEdit.append('userId', userDetail.id);
              dataEdit.append('name', e.target.name.value);
              dataEdit.append('order', e.target.order.value);
              if (e.target.modul.files[0] !== undefined) {
                dataEdit.append('modul', e.target.modul.files[0]);
              }
              if (e.target.video.files[0] !== undefined) {
                dataEdit.append('video', e.target.video.files[0]);
              }
              if (e.target.file_transcript.files[0] !== undefined) {
                dataEdit.append('file_transcript', e.target.file_transcript.files[0]);
              }
              dataEdit.append('topicId', material.topicId);
              dataEdit.append('transcript', content);
              const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
              const options = {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${token}`,
                },
                onUploadProgress: (dataProgress) => {
                  setProgress(Math.round((100 * dataProgress.loaded) / dataProgress.total));
                },
              };
              setLoading(true);
              axios.patch(`${URL_MATERIAL + id}/`, dataEdit, options)
                .then(() => {
                  history.push({
                    pathname: `/admin/course-material/${material.topicId}`,
                    search: '?tabs=1',
                  });
                  setLoading(false);
                })
                .catch(() => {
                  setLoading(false);
                  message.error('Something went wrong!');
                });
            }}
          >
            <span className="form__form-group-label">Order Material</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="order"
                  disabled={!isEditable}
                  component="input"
                  type="number"
                />
              </div>
            </div>
            <span className="form__form-group-label">Title Material</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="name"
                  disabled={!isEditable}
                  component="input"
                  type="text"
                />
              </div>
            </div>
            <span className="form__form-group-label">Modul</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="modul"
                  component={renderFileInputField}
                  type="file"
                  onChange={(e) => {
                    if ((e.file.type.includes('application/vnd.openxmlformats-officedocument.presentationml.presentation')) || (e.file.type.includes('application/pdf') || (e.file.type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')))) {
                      setAlertModul(false);
                    } else {
                      setAlertModul(true);
                    }
                  }}
                />
              </div>
              <p>Current Link: {material !== null ? (
                <a href={material.modul} target="_blank" rel="noreferrer">
                  {material.modul}
                </a>
              ) : '-'}
              </p>
              {alertModul && (
                <span style={{ color: 'red' }}><RiErrorWarningLine /> Please setup file pdf / word / ppt</span>
              )}
            </div>
            <span className="form__form-group-label">Video</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="video"
                  component={renderFileInputField}
                  type="file"
                  onChange={(e) => {
                    if (!e.file.type.includes('video')) {
                      setAlertVideo(true);
                    } else {
                      setAlertVideo(false);
                    }
                  }}
                />
              </div>
              <p>Current Link: {material !== null ? (
                <a href={material.video} target="_blank" rel="noreferrer">
                  {material.video}
                </a>
              ) : '-'}
              </p>
              {alertVideo && (
              <span style={{ color: 'red' }}><RiErrorWarningLine /> Please setup with .mp4 type file</span>
              )}
            </div>
            <span className="form__form-group-label">Transcript Video</span>
            <div className="form__form-group">
              <div className="form__form-group-field w-100-role-application">
                <input
                  id="my-file"
                  type="file"
                  name="my-file"
                  style={{ display: 'none' }}
                />
                <Editor
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  apiKey="kdsr6jcmxjffjg6z20pb4vtbg0fegwqdqy6ltsxoahuo3dvg"
                  initialValue={data?.transcript}
                  init={{
                    skin: 'snow',
                    icons: 'thin',

                    height: 500,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen textcolor ',
                      'insertdatetime media table paste code help wordcount',
                      'image code',
                    ],
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    textcolor_rows: '4',
                    file_picker_callback(callback, value, meta) {
                      if (meta.filetype === 'image') {
                        const input = document.getElementById('my-file');
                        input.click();
                        input.onchange = function () {
                          const file = input.files[0];
                          const reader = new FileReader();
                          reader.onload = function (e) {
                            callback(e.target.result, {
                              alt: file.name,
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                      }
                    },

                    content_style:
                      "@import url('http://fonts.gstatic.com/s/nunito/v7/1TiHc9yag0wq3lDO9cw0vpBw1xU1rKptJj_0jans920.woff2');",
                    font_formats: 'Nunito',

                    toolbar:
                      'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
                  }}
                  onEditorChange={handleEditorChange}
                  outputFormat="html"
                />
              </div>
            </div>
            <span className="form__form-group-label">File Transcript</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="file_transcript"
                  component={renderFileInputField}
                  type="file"
                  onChange={(e) => {
                    if ((e.file.type.includes('application/vnd.openxmlformats-officedocument.presentationml.presentation')) || (e.file.type.includes('application/pdf') || (e.file.type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')))) {
                      setAlertFileTranscript(true);
                    } else {
                      setAlertFileTranscript(false);
                    }
                  }}
                />
              </div>
              <p>Current Link: {material !== null ? (
                <a href={material.file_transcript} target="_blank" rel="noreferrer">
                  {material.file_transcript}
                </a>
              ) : '-'}
              </p>
              {alertTranscript && (
                <span style={{ color: 'red' }}><RiErrorWarningLine /> Please setup file pdf</span>
              )}
            </div>
            {loading && (
              <Col md={12} lg={12}>
                <Progress animated value={progress}>{progress}%</Progress>
              </Col>
            )}
            <ButtonToolbar className="form__button-toolbar">
              <Row className="mt-4">
                <Col md={12}>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setEditable(!isEditable)}
                    color={isEditable ? 'danger' : 'primary'}
                  >
                    {!isEditable ? 'Change' : 'Cancel'}
                  </button>
                  {isEditable && (
                    <button
                      type="submit"
                      className="btn btn-primary"
                    >
                      Save
                    </button>
                  )}
                  {isEditable === false && (
                    <button
                      type="button"
                      size="sm"
                      onClick={() => toggle(material?.id)}
                      className="btn btn-danger"
                    >
                      Delete
                    </button>
                  )}
                  <Modal
                    toggle={toggle}
                    isOpen={modal}
                    backdrop={false}
                  >
                    <ModalHeader toggle={toggle}>
                      Delete Material?
                    </ModalHeader>
                    <ModalBody>
                      Are you sure you want to Delete this Material?
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        onClick={() => {
                          const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                          const options = {
                            headers: {
                              'Content-Type':
                            'application/json',
                              Authorization: `Token ${token}`,
                            },
                          };
                          axios
                            .delete(
                              `${URL_MATERIAL}${confirm}`,
                              options,
                            )
                            .then(() => {
                              history.push({
                                pathname: `/admin/course-material/${material.topicId}`,
                                search: '?tabs=1',
                              });
                            })
                            .catch(() => {
                              message.error('Something went wrong!');
                            });
                        }}
                      >
                        Delete
                      </Button>
                    </ModalFooter>
                  </Modal>
                </Col>
              </Row>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

// EditMaterialForm.propTypes = {
//   onSubmit: PropTypes.func.isRequired,
// };

export default reduxForm({
  form: 'edit_material', // a unique identifier for this form
})(EditMaterialForm);
