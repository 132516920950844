/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Col,
  ButtonToolbar,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import axios from 'axios';
import { FaTrash } from 'react-icons/fa';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/Types';
import { ContactProps, MessageProps } from '../../../../shared/prop-types/ChatProps';
import { URL_DISCUSSION } from '../../../../utils/EndPoints';

const Ava = `${process.env.PUBLIC_URL}/img/no_image.png`;
const bubbleClass = (active) => {
  classNames({
    chat__bubble: true,
    'chat__bubble--active': active,
  });
};

const ChatBubble = ({ contact, message, active }) => {
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(0);
  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    setConfirm(id);
    setModal(!modal);
  };

  return (
    <div className={bubbleClass(active)}>
      <div className="chat__bubble-avatar">
        <img src={message?.user_detail?.photo ?? Ava} alt="ava" />
      </div>
      {message?.file
        ? (
          <div className="chat__bubble-message-wrap chat__bubble-message-wrap--file">
            <p className="chat__bubble-contact-name">{contact?.name}</p>
            <img src={message.user_detail.photo} alt={message?.message} />
            <p className="chat__bubble-date">{message?.created_at}</p>
            <div className="chat__bubble-download-wrap">
              <p className="chat__bubble-file-name">
                <span>{message?.message}</span>
                <span>{message?.file?.size}</span>
              </p>
              <a className="chat__bubble-download" href={message?.file?.preview} download>Download</a>
            </div>
          </div>
        ) : (
          <div className="chat__bubble-message-wrap">
            <Row className="align-items-center">
              <Col xs={8} md={10}>
                <p className="chat__bubble-contact-name">{message?.user_detail?.name}</p>
                <p className="chat__bubble-message">{message?.comment}</p>
                <p className="chat__bubble-date">{message?.created_at}</p>
              </Col>
              <Col xs={1} md={2} className="d-flex justify-content-end">
                <ButtonToolbar>
                  <button
                    type="button"
                    size="sm"
                    onClick={() => toggle(message?.id)}
                    style={{
                      border: 'none', padding: '0', background: 'transparent',
                    }}
                  >
                    <FaTrash color="red" font-size="20px" />
                  </button>
                  <Modal
                    toggle={toggle}
                    isOpen={modal}
                    backdrop={false}
                  >
                    <ModalHeader toggle={toggle}>
                      Delete Reply
                    </ModalHeader>
                    <ModalBody>
                      Are you sure you want to Delete this Reply?
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        onClick={() => {
                          const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                          const options = {
                            headers: {
                              'Content-Type':
                                    'application/json',
                              Authorization: `Token ${token}`,
                            },
                          };
                          setLoading(true);
                          axios
                            .delete(
                              `${URL_DISCUSSION}${confirm}`,
                              options,
                            )
                            .then(() => {
                              setLoading(false);
                              window.location.reload();
                            })
                            .catch(() => {
                              message.error('Something went wrong!');
                              setLoading(false);
                            });
                        }}
                        loading={loading}
                      >
                        Delete
                      </Button>
                    </ModalFooter>
                  </Modal>
                </ButtonToolbar>
              </Col>
            </Row>
          </div>
        )}
    </div>
  );
};

ChatBubble.propTypes = {
  contact: ContactProps.isRequired,
  message: MessageProps.isRequired,
  active: PropTypes.bool,
};

ChatBubble.defaultProps = {
  active: false,
};

export default ChatBubble;
