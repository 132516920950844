/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Progress,
} from 'reactstrap';
import { message } from 'antd';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { URL_QUESTION, URL_QUESTION_BY_IDQUIZ } from '../../../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../../../utils/Types';
import renderSelectField from '../../../../../../shared/components/form/Select';

const CreateQuizQuestionForm = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { id } = useParams();
  const [progress, setProgress] = useState(null);
  const [bobotCalculate, setBobotCalculate] = useState(0);
  const editorRef = useRef(null);

  // loading
  const [loading, setLoading] = useState(false);

  const [content, setContents] = useState('');
  const handleEditorChange = (contents) => {
    setContents(contents);
  };

  const optionsAnswer = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
  ];

  useEffect(() => {
    let currentBobot = 0;
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    setLoading(true);
    axios
      .get(`${URL_QUESTION_BY_IDQUIZ + id}/`, options)
      .then((res) => {
        res.data.map((q) => {
          currentBobot += q.weight_correct;
        });
        setBobotCalculate(currentBobot);
        setLoading(false);
      })
      .catch(() => {
        message.error('Something went wrong!');
      });
  }, []);

  const availableBobot = 100 - bobotCalculate;

  return (
    <Col md={12} lg={12}>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          {contextHolder}
          <form
            className="form"
            onSubmit={(e) => {
              e.preventDefault();
              const data = new FormData();
              data.append('question_text', content);
              data.append('A', e.target.A.value);
              data.append('B', e.target.B.value);
              data.append('C', e.target.C.value);
              data.append('D', e.target.D.value);
              data.append('correct_answer', e.target.correct_answer.value);
              data.append('weight_correct', e.target.weight_correct.value);
              data.append('quizId', id);
              const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
              const options = {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${token}`,
                },
                onUploadProgress: (dataProgress) => {
                  setProgress(Math.round((100 * dataProgress.loaded) / dataProgress.total));
                },
              };
              setLoading(true);
              axios.post(URL_QUESTION, data, options)
                .then(() => {
                  window.location.reload();
                  setLoading(false);
                })
                .catch(() => {
                  setLoading(false);
                  message.error('Something went wrong!');
                });
            }}
          >
            <span className="form__form-group-label">Question</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <input
                  id="my-file"
                  type="file"
                  name="my-file"
                  style={{ display: 'none' }}
                />
                <Editor
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  apiKey="kdsr6jcmxjffjg6z20pb4vtbg0fegwqdqy6ltsxoahuo3dvg"
                  init={{
                    skin: 'snow',
                    icons: 'thin',

                    height: 500,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen textcolor ',
                      'insertdatetime media table paste code help wordcount',
                      'image code',
                    ],
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    textcolor_rows: '4',
                    file_picker_callback(callback, value, meta) {
                      if (meta.filetype === 'image') {
                        const input = document.getElementById('my-file');
                        input.click();
                        input.onchange = function () {
                          const file = input.files[0];
                          const reader = new FileReader();
                          reader.onload = function (e) {
                            callback(e.target.result, {
                              alt: file.name,
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                      }
                    },

                    content_style:
                      "@import url('http://fonts.gstatic.com/s/nunito/v7/1TiHc9yag0wq3lDO9cw0vpBw1xU1rKptJj_0jans920.woff2');",
                    font_formats: 'Nunito',

                    toolbar:
                      'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
                  }}
                  onEditorChange={handleEditorChange}
                  outputFormat="html"
                />
              </div>
            </div>
            <span className="form__form-group-label">A</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="A"
                  component="input"
                  type="text"
                  placeholder="Answer A"
                  required
                />
              </div>
            </div>
            <span className="form__form-group-label">B</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="B"
                  component="input"
                  type="text"
                  placeholder="Answer B"
                  required
                />
              </div>
            </div>
            <span className="form__form-group-label">C</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="C"
                  component="input"
                  type="text"
                  placeholder="Answer C"
                  required
                />
              </div>
            </div>
            <span className="form__form-group-label">D</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="D"
                  component="input"
                  type="text"
                  placeholder="Answer D"
                  required
                />
              </div>
            </div>
            <span className="form__form-group-label">Correct Answer</span>
            <div className="form__form-group">
              <div className="form__form-group-field" style={{ display: 'block' }}>
                <Field
                  name="correct_answer"
                  component={renderSelectField}
                  type="text"
                  options={optionsAnswer}
                  defaultValue={optionsAnswer[0]}
                  placeholder="Select One"
                  required
                />
              </div>
            </div>
            <span className="form__form-group-label">Weight (Max: <b>{availableBobot}</b>)</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="weight_correct"
                  component="input"
                  type="number"
                  min="1"
                  max={`${availableBobot}`}
                  onChange={(e) => {
                    if (parseInt(e.target.value) > availableBobot) {
                      messageApi.open({
                        type: 'warning',
                        content: 'Weight The value entered cannot exceed the available weight',
                      });
                    }
                    if (parseInt(e.target.value) === 0) {
                      messageApi.open({
                        type: 'warning',
                        content: 'The value entered cannot be 0',
                      });
                    }
                    if (parseInt(e.target.value) < 0) {
                      messageApi.open({
                        type: 'warning',
                        content: 'The weight of the value entered cannot be minus',
                      });
                    }
                  }}
                  placeholder={`Weight max: ${availableBobot})`}
                  required
                />
              </div>
            </div>

            {loading && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

// CreateQuizQuestionForm.propTypes = {
//   onSubmit: PropTypes.func.isRequired,
// };

export default reduxForm({
  form: 'create_question', // a unique identifier for this form
})(CreateQuizQuestionForm);
