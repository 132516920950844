/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonToolbar,
  Card, CardBody, Col,
} from 'reactstrap';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';
import { URL_QUESTION_BY_IDQUIZ, URL_QUIZ } from '../../../../../utils/EndPoints';
import ListDataQuestion from './components/listDataQuizQuestion';
import QuizQuestionListTable from './components/quizQuestionList';

const QuizQuestion = () => {
  const history = useHistory();
  const params = useParams();
  const { id } = useParams();
  const [quiz, setQuiz] = useState(null);
  const [question, setQuestion] = useState([]);
  const [loading, setLoading] = useState(false);
  const listDataTable = ListDataQuestion(question);

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    setLoading(true);
    axios
      .get(`${URL_QUIZ}${id}/`, options)
      .then((res) => {
        setQuiz(res.data);
        setLoading(false);
      })
      .catch(() => {
      });
    setLoading(true);
    axios
      .get(`${URL_QUESTION_BY_IDQUIZ}${params.id}/`, options)
      .then((res) => {
        setQuestion(res.data);
        setLoading(false);
      })
      .catch(() => {
      });
  }, []);

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <ButtonToolbar className="form__button-toolbar">
          <Button
            size="sm"
            color="danger"
            onClick={() => {
              history.push({
                pathname: `/admin/course-material/${quiz.topicId}`,
                search: '?tabs=2',
              });
            }}
          >
            Back
          </Button>
        </ButtonToolbar>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Question Examination {quiz?.name}</h5>
            {/* <h5 className="subhead">Use default tabs with classes
            <span className="red-text"> tabs--justify tabs--bordered-bottom</span>
          </h5> */}
          </div>
          <div className="tabs tabs--justify tabs--bordered-bottom">
            <QuizQuestionListTable
              listDataTable={listDataTable}
              isLoading={loading}
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default QuizQuestion;
