/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable max-len */

/* eslint-disable react/react-in-jsx-scope */
import
React,
{ useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const PhotoFormatter = (value) => (
  <div>
    <img
      src={value}
      alt="Image"
      style={{
        width: '50px', height: 'auto', marginRight: '6px', borderRadius: '5px',
      }}
    />
  </div>
);

const teacherDetail = (history, info) => {
  history.push({
    pathname: `/admin/instructor-detail/${info.id}`,
    state: { data: info },
  });
};

const ListDataTeacher = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: 110,
      },
      {
        Header: 'Position',
        accessor: 'position',
        width: 110,
        Cell: ({ value }) => (
          <span>{value?.length > 20 ? `${value.substring(0, 20)}...` : value}</span>
        ),
      },
      {
        Header: 'Organization',
        accessor: 'organization',
        width: 110,
        disableSortBy: true,
        Cell: ({ value }) => (
          <span>{value?.length > 20 ? `${value.substring(0, 20)}...` : value}</span>
        ),
      },
      {
        Header: 'Country',
        accessor: 'country',
        width: 110,
      },
      {
        Header: 'Profile',
        accessor: 'profile',
        width: 110,
        Cell: ({ value }) => (
          <span>{value?.length > 150 ? `${value.substring(0, 150)}...` : value}</span>
        ),
      },
      {
        Header: 'Image',
        accessor: 'image',
        width: 110,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Action',
        accessor: 'status',
        width: 110,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    for (let i = 0; i < dataAPI.length; i += 1) {
      data.push({
        id: dataAPI[i].id,
        number: `${i + 1}`,
        name: dataAPI[i]?.name,
        position: dataAPI[i]?.position,
        organization: dataAPI[i]?.organization,
        country: dataAPI[i]?.country,
        image: PhotoFormatter(`${dataAPI[i]?.image}`),
        status: [
          // eslint-disable-next-line no-loop-func
          // eslint-disable-next-line max-len
          (<Button key={dataAPI[i].id} color="outline-success" onClick={() => teacherDetail(history, dataAPI[i])}>Detail</Button>),
        ],
      });
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataTeacher;
