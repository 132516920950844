/* eslint-disable indent */
/* eslint-disable no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container, Row,
} from 'reactstrap';
import { message } from 'antd';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/Types';
import { URL_FAQ } from '../../../../utils/EndPoints';
import ListDataReview from './components/ListData';
import FAQListingTable from './components/FAQListing';

const FAQListing = () => {
  const [faq, setFAQ] = useState([]);
  const listDataTable = ListDataReview(faq);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(URL_FAQ, options)
      .then((res) => {
        setFAQ(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error('Something went wrong!');
      });
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <FAQListingTable
          listDataTable={listDataTable}
          isLoading={isLoading}
        />
      </Row>
    </Container>
  );
};
export default FAQListing;
