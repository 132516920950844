/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Progress,
} from 'reactstrap';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Spin, message } from 'antd';
import { URL_TEACHER_REGIS } from '../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/Types';

const MyAccountForm = (stateOri) => {
  const [progress, setProgress] = useState(null);
  const [isEditable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { initialize } = stateOri;
  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    setLoading(true);
    axios
      .get(`${URL_TEACHER_REGIS}`, options)
      .then((res) => {
        initialize(res.data);
        setLoading(false);
      })
      .catch((err) => {
        message.error(err?.response?.status ?? 'Something went wrong!');
        setLoading(true);
      });
  }, []);

  const onSubmit = (e) => {
    // setLoading(true);
    e.preventDefault();
    const data = new FormData();
    data.append('name', e.target.name.value);
    data.append('email', e.target.email.value);
    data.append('phone', e.target.phone.value);
    data.append('job', e.target.job.value);
    data.append('organization', e.target.organization.value);
    data.append('street_address', e.target.street_address.value);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };
    axios
      .patch(`${URL_TEACHER_REGIS}/`, data, options)
      .then(() => {
        window.location.reload();
        // history.push('/admin/dashboard');
        // setLoading(false);
      })
      .catch((err) => {
        setProgress(null);
        message.error(err?.response?.data?.email ?? 'Something went wrong!');
        // setLoading(false);
      });
  };

  return (
    <Col md={12} lg={12}>
      <Card className="card--not-full-height">
        <ButtonToolbar className="form__button-toolbar">
          <Button size="sm" color="danger" onClick={history.goBack}>
            Back
          </Button>
        </ButtonToolbar>
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Account Details</h5>
          </div>

          {loading ? <Spin /> : (
            <form className="form" onSubmit={onSubmit}>
              <div className="col-lg-6 col-md-12">
                <span className="form__form-group-label">Full Name</span>
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Field
                      name="name"
                      component="input"
                      type="text"
                      placeholder="Input Full Name"
                      disabled={!isEditable}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <span className="form__form-group-label">Email</span>
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Field
                      name="email"
                      component="input"
                      type="email"
                      placeholder="Input Email Address"
                      disabled={!isEditable}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <span className="form__form-group-label">Phone</span>
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Field
                      name="phone"
                      component="input"
                      type="text"
                      placeholder="Input Phone Number"
                      disabled={!isEditable}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <span className="form__form-group-label">Job</span>
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Field
                      name="job"
                      component="input"
                      type="text"
                      placeholder="Input Your Job"
                      disabled={!isEditable}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <span className="form__form-group-label">Organization</span>
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Field
                      name="organization"
                      component="input"
                      type="text"
                      placeholder="Input Your Organization"
                      disabled={!isEditable}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <span className="form__form-group-label">Street Address</span>
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Field
                      name="street_address"
                      component="input"
                      type="text"
                      placeholder="Input Street Address"
                      disabled={!isEditable}
                    />
                  </div>
                </div>
              </div>
              {progress !== null && (
                <Card>
                  <Col md={12} lg={12}>
                    <Progress animated value={progress}>{progress}%</Progress>
                  </Col>
                </Card>
              )}

              <ButtonToolbar className="form__button-toolbar">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setEditable(!isEditable)}
                  color={isEditable ? 'danger' : 'primary'}
                >
                  {' '}
                  {!isEditable ? 'Change' : 'Cancel'}
                </button>
                {isEditable && (
                  <button
                    type="submit"
                    className="btn btn-primary"
                  >
                    {' '}
                    Save
                  </button>
                )}
              </ButtonToolbar>
            </form>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'teacher_edittopic_create', // a unique identifier for this form
})(MyAccountForm);
