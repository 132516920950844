/* eslint-disable no-shadow */

import React from 'react';
import {
  Container,
} from 'reactstrap';
import CreateUserForm from './components/createUserForm';

const CreateUser = () => (
  <Container className="dashboard">
    <CreateUserForm />
  </Container>
);
export default CreateUser;
