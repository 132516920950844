/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
import
React,
{ useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const liveSessionEdit = (history, info) => {
  history.push({
    pathname: `/admin/livesession-edit/${info.id}`,
    state: { data: info },
  });
};

const ListDataLiveSession = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'Number',
        accessor: 'number',
        width: 20,
        disableSortBy: true,
      },
      {
        Header: 'Session',
        accessor: 'title',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Date',
        accessor: 'date',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Time',
        accessor: 'time',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Link Meeting / Meeting ID',
        accessor: 'link_meeting',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Password',
        accessor: 'passcode_meeting',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    for (let i = 0; i < dataAPI?.length; i += 1) {
      data.push({
        id: dataAPI[i].id,
        number: `${i + 1}`,
        title: dataAPI[i]?.title,
        date: dataAPI[i]?.date,
        time: dataAPI[i]?.time,
        link_meeting: dataAPI[i]?.link_meeting,
        passcode_meeting: dataAPI[i]?.passcode_meeting,
        action: [
          // eslint-disable-next-line no-loop-func
          // eslint-disable-next-line max-len
          (<Button key={Math.random()} color="outline-success" onClick={() => liveSessionEdit(history, dataAPI[i])}>Detail</Button>),
        ],
      });
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataLiveSession;
