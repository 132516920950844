/* eslint-disable no-shadow */

import React from 'react';
import {
  Container,
} from 'reactstrap';
import PartnerEditForm from './components/EditPartnerForm';

const EditPartner = () => (
  <Container className="dashboard">
    <PartnerEditForm />
  </Container>
);
export default EditPartner;
