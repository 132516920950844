/* eslint-disable max-len */

/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react';

const ListDataQuizScore = (dataAPI) => {
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableSortBy: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Exam',
        accessor: 'exam',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Type',
        accessor: 'type',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Score',
        accessor: 'score',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Certificate',
        accessor: 'certificate',
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    for (let i = 0; i < dataAPI.length; i += 1) {
      data.push({
        id: dataAPI[i].id,
        number: `${i + 1}`,
        name: `${dataAPI[i]?.answer_detail?.user_detail?.name}`,
        exam: `${dataAPI[i]?.answer_detail?.quiz_detail?.name}`,
        type: `${dataAPI[i]?.answer_detail?.quiz_detail?.isFinalQuiz === true ? '✔' : '✖'}`,
        certificate: dataAPI[i]?.link_certificate ?? '-',
        score: `${dataAPI[i]?.score}`,
      });
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataQuizScore;
