/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import { Spin } from 'antd';
import Collapse from '../../../../../shared/components/Collapse';
import ReactTableBase from '../../../../../shared/components/table/ReactTableBase';
import CreateTopicForm from './createTopicForm';

const TopicListTable = ({ listDataTable, isLoading }) => {
  const tableConfig = {
    isEditable: false,
    isSortable: true,
    isResizable: false,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="dashboard__booking-card">
          <Collapse title="Create New Curriculum" className="with-shadow">
            <CreateTopicForm />
          </Collapse>

          <div className="card__title">
            <h5 className="bold-text">Listing Curriculum</h5>
          </div>
          {isLoading ? <Spin /> : (
            <ReactTableBase
              columns={listDataTable.tableHeaderData}
              data={listDataTable.tableRowsData}
              tableConfig={tableConfig}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

TopicListTable.propTypes = {
  listDataTable: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default TopicListTable;
