import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../Account/LogIn';
import HomeAdmin from '../Admin/HomePage';
import CourseList from '../Admin/Course/List';
import CreateCourse from '../Admin/Course/Create';
import CourseTopic from '../Admin/Course/Topic';
import CourseDetail from '../Admin/Course/Detail';
import EditTopic from '../Admin/Course/Topic/Edit';
import CourseMaterial from '../Admin/Course/Material';
import EditMaterial from '../Admin/Course/Material/Edit';
import EditQuiz from '../Admin/Course/Quiz/Edit';
import QuizQuestion from '../Admin/Course/Quiz/Question';
import EditQuizQuestion from '../Admin/Course/Quiz/Question/Edit';
import EditInstructor from '../Admin/Course/Instructor/Edit';
import PartnerList from '../Admin/Partner/List';
import CreatePartner from '../Admin/Partner/Create';
import EditPartner from '../Admin/Partner/Detail';
import EnrollmentListing from '../Admin/Enrollment/List';
import ReviewListing from '../Admin/Review/List';
import EditReview from '../Admin/Review/Detail';
import CreateEnroll from '../Admin/Enrollment/Create';
import EditEnroll from '../Admin/Enrollment/Detail';
import EditLiveSession from '../Admin/Course/LiveSession/Edit';
import QuizScore from '../Admin/Course/Quiz/Score';
import TeacherList from '../Admin/Teacher/List';
import ResetPassword from '../Account/ForgotPassword/reset';
import PrivateRoute from '../../utils/PrivateRoute';
import NewPassword from '../Account/ForgotPassword/NewPassword';
import EditTeacher from '../Admin/Teacher/Detail';
import MyAccount from '../Account/MyAccount';
import UserList from '../Admin/User/List';
import EditUser from '../Admin/User/Detail';
import CategoryListing from '../Admin/Category/List';
import CreateCategory from '../Admin/Category/Create';
import EditCategory from '../Admin/Category/Detail';
import HeroListing from '../Admin/Hero/List';
import CreateHero from '../Admin/Hero/Create';
import EditHero from '../Admin/Hero/Detail';
import CreateInstructor from '../Admin/Teacher/Create';
import CreateUser from '../Admin/User/Create';
import Chat from '../Admin/Chat';
import OrderList from '../Admin/Order/List';
import OrderDetail from '../Admin/Order/Detail';
import WishlistListing from '../Admin/Wishlist/List';
import WishlistDetail from '../Admin/Wishlist/Detail';
import CreateWishlist from '../Admin/Wishlist/Create';
import FAQListing from '../Admin/Faq/List';
import CreateFAQ from '../Admin/Faq/Create';
import FAQDetail from '../Admin/Faq/Detail';
import TypeListing from '../Admin/Type/List';
import CreateType from '../Admin/Type/Create';
import EditCourseType from '../Admin/Type/Detail';

const Pages = () => (
  <Switch>
    <PrivateRoute path="/admin/dashboard" component={HomeAdmin} />
    <PrivateRoute path="/admin/course" component={CourseList} />
    <PrivateRoute path="/admin/new-course" component={CreateCourse} />
    <PrivateRoute path="/admin/course-curriculum/:id" component={CourseTopic} />
    <PrivateRoute path="/admin/course-detail/:id" component={CourseDetail} />
    <PrivateRoute path="/admin/curriculum-edit/:id" component={EditTopic} />
    <PrivateRoute path="/admin/course-material/:id" component={CourseMaterial} />
    <PrivateRoute path="/admin/material-edit/:id" component={EditMaterial} />
    <PrivateRoute path="/admin/quiz-edit/:id" component={EditQuiz} />
    <PrivateRoute path="/admin/quiz-question/:id" component={QuizQuestion} />
    <PrivateRoute path="/admin/quiz-score/:id" component={QuizScore} />
    <PrivateRoute path="/admin/quiz-question-edit/:id" component={EditQuizQuestion} />
    <PrivateRoute path="/admin/livesession-edit/:id" component={EditLiveSession} />
    <PrivateRoute path="/admin/instructor-edit/:id" component={EditInstructor} />
    <PrivateRoute path="/admin/topic" component={CategoryListing} />
    <PrivateRoute path="/admin/new-topic" component={CreateCategory} />
    <PrivateRoute path="/admin/detail-topic/:id" component={EditCategory} />
    <PrivateRoute path="/admin/hero" component={HeroListing} />
    <PrivateRoute path="/admin/new-hero" component={CreateHero} />
    <PrivateRoute path="/admin/hero-detail/:id" component={EditHero} />
    <PrivateRoute path="/admin/partners" component={PartnerList} />
    <PrivateRoute path="/admin/new-partner" component={CreatePartner} />
    <PrivateRoute path="/admin/partner-detail/:id" component={EditPartner} />
    <PrivateRoute path="/admin/enrollment" component={EnrollmentListing} />
    <PrivateRoute path="/admin/new-enrollment" component={CreateEnroll} />
    <PrivateRoute path="/admin/enrollment-detail/:id" component={EditEnroll} />
    <PrivateRoute path="/admin/review" component={ReviewListing} />
    <PrivateRoute path="/admin/review-detail/:id" component={EditReview} />
    <PrivateRoute path="/admin/instructor" component={TeacherList} />
    <PrivateRoute path="/admin/new-instructor" component={CreateInstructor} />
    <PrivateRoute path="/admin/instructor-detail/:id" component={EditTeacher} />
    <PrivateRoute path="/admin/myaccount" component={MyAccount} />
    <PrivateRoute path="/admin/user" component={UserList} />
    <PrivateRoute path="/admin/new-user" component={CreateUser} />
    <PrivateRoute path="/admin/user-detail/:id" component={EditUser} />
    <PrivateRoute path="/admin/forum/:id" component={Chat} />
    <PrivateRoute path="/admin/order" component={OrderList} />
    <PrivateRoute path="/admin/order-detail/:id" component={OrderDetail} />
    <PrivateRoute path="/admin/wishlist" component={WishlistListing} />
    <PrivateRoute path="/admin/wishlist-detail/:id" component={WishlistDetail} />
    <PrivateRoute path="/admin/new-wishlist" component={CreateWishlist} />
    <PrivateRoute path="/admin/faq" component={FAQListing} />
    <PrivateRoute path="/admin/new-faq" component={CreateFAQ} />
    <PrivateRoute path="/admin/faq-detail/:id" component={FAQDetail} />
    <PrivateRoute path="/admin/type" component={TypeListing} />
    <PrivateRoute path="/admin/new-type" component={CreateType} />
    <PrivateRoute path="/admin/new-type-detail/:id" component={EditCourseType} />
  </Switch>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <PrivateRoute path="/admin/" component={Pages} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/log_in" component={LogIn} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/new-password" component={NewPassword} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
