/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress, Row,
} from 'reactstrap';
import { Editor } from '@tinymce/tinymce-react';
import { message } from 'antd';
import { URL_QUESTION } from '../../../../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../../../../utils/Types';
import renderSelectField from '../../../../../../../shared/components/form/Select';

const EditQuizQuestionForm = (stateOri) => {
  const history = useHistory();
  const { id } = useParams();

  const editorRef = useRef(null);
  const [isEditable, setEditable] = useState(false);
  const [progress, setProgress] = useState();

  // loading
  const [loading, setLoading] = useState(false);

  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;
  const [contents, setContents] = useState(data?.correct_answer);
  const handleEditorChange = (content) => {
    setContents(content);
  };
  const [confirm, setConfirm] = useState(0);
  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    setConfirm(id);
    setModal(!modal);
  };

  const optionsAnswer = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
  ];

  useEffect(() => {
    initialize({
      ...data,
      correct_answer: { value: `${data?.correct_answer}`, label: data?.correct_answer },
    });
  }, []);

  return (
    <Col md={12} lg={12}>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <form
            className="form"
            onSubmit={(e) => {
              // setLoading(true);
              e.preventDefault();
              const data = new FormData();
              if (e.target.content.value !== '') {
                data.append('question_text', e.target.content.value);
              }
              if (e.target.A.value !== '') {
                data.append('A', e.target.A.value);
              }
              if (e.target.B.value !== '') {
                data.append('B', e.target.B.value);
              }
              if (e.target.C.value !== '') {
                data.append('C', e.target.C.value);
              }
              if (e.target.D.value !== '') {
                data.append('D', e.target.D.value);
              }
              data.append('correct_answer', e.target.correct_answer.value);
              if (e.target.weight_correct.value !== '') {
                data.append('weight_correct', e.target.weight_correct.value);
              }
              // data.append('topicId', data.quizId);
              const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
              const options = {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${token}`,
                },
                onUploadProgress: (data) => {
                  setProgress(Math.round((100 * data.loaded) / data.total));
                },
              };

              setLoading(true);
              axios.patch(`${URL_QUESTION}${id}/`, data, options)
                .then((res) => {
                  history.push(`/admin/quiz-question/${res.data.quizId}`);
                  setLoading(false);
                })
                .catch(() => {
                  setLoading(false);
                  message.error('Something went wrong!');
                });
            }}
          >
            <input
              type="hidden"
              name="content"
              id=""
              value={contents}
              defaultValue={contents}
            />
            <span className="form__form-group-label">Question</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <input
                  id="my-file"
                  type="file"
                  name="my-file"
                  style={{ display: 'none' }}
                />
                <Editor
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue={data.question_text}
                  apiKey="kdsr6jcmxjffjg6z20pb4vtbg0fegwqdqy6ltsxoahuo3dvg"
                  init={{
                    skin: 'snow',
                    icons: 'thin',
                    height: 500,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen textcolor ',
                      'insertdatetime media table paste code help wordcount',
                      'image code',
                    ],
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    textcolor_rows: '4',
                    file_picker_callback(callback, value, meta) {
                      if (meta.filetype === 'image') {
                        const input = document.getElementById('my-file');
                        input.click();
                        input.onchange = function () {
                          const file = input.files[0];
                          const reader = new FileReader();
                          reader.onload = function (e) {
                            callback(e.target.result, {
                              alt: file.name,
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                      }
                    },

                    content_style:
                      "@import url('http://fonts.gstatic.com/s/nunito/v7/1TiHc9yag0wq3lDO9cw0vpBw1xU1rKptJj_0jans920.woff2');",
                    font_formats: 'Nunito',

                    toolbar:
                      'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
                  }}
                  onEditorChange={handleEditorChange}
                  outputFormat="html"
                  disabled={!isEditable}
                />
              </div>
            </div>
            <span className="form__form-group-label">A</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="A"
                  disabled={!isEditable}
                  component="input"
                  type="text"
                />
              </div>
            </div>
            <span className="form__form-group-label">B</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="B"
                  component="input"
                  disabled={!isEditable}
                  type="text"
                />
              </div>
            </div>
            <span className="form__form-group-label">C</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="C"
                  disabled={!isEditable}
                  component="input"
                  type="text"
                />
              </div>
            </div>
            <span className="form__form-group-label">D</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="D"
                  disabled={!isEditable}
                  component="input"
                  type="text"
                />
              </div>
            </div>
            <span className="form__form-group-label">Correct Answer</span>
            <div className="form__form-group">
              <div className="form__form-group-field" style={{ display: 'block' }}>
                <Field
                  name="correct_answer"
                  component={renderSelectField}
                  type="text"
                  options={optionsAnswer}
                  defaultValue={optionsAnswer[0]}
                  placeholder="Select One"
                  required
                />
              </div>
            </div>
            <span className="form__form-group-label">Weight</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="weight_correct"
                  component="input"
                  type="number"
                  placeholder="Bobot Nilai"
                  disabled={!isEditable}
                />
              </div>
            </div>

            {loading && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <Row className="mt-4">
                <Col md={12}>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setEditable(!isEditable)}
                    color={isEditable ? 'danger' : 'primary'}
                  >
                    {' '}
                    {!isEditable ? 'Change' : 'Cancel'}
                  </button>
                  {isEditable && (
                  <button
                    type="submit"
                    className="btn btn-primary"
                  >
                    {' '}
                    Save
                  </button>
                  )}
                  {isEditable === false && (
                  <button
                    type="button"
                    size="sm"
                    onClick={() => toggle(data.id)}
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                  )}
                  <Modal
                    toggle={toggle}
                    isOpen={modal}
                    backdrop={false}
                  >
                    <ModalHeader toggle={toggle}>
                      Delete Question
                    </ModalHeader>
                    <ModalBody>
                      Are you sure you want to Delete this Question?
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        onClick={() => {
                          const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                          const options = {
                            headers: {
                              'Content-Type':
                            'application/json',
                              Authorization: `Token ${token}`,
                            },
                          };
                          axios
                            .delete(
                              `${URL_QUESTION}${confirm}`,
                              options,
                            )
                            .then(() => {
                              history.push(`/admin/quiz-question/${data.quizId}`);
                            })
                            .catch(() => {
                              message.error('Something went wrong!');
                            });
                        }}
                      >
                        Delete
                      </Button>
                    </ModalFooter>
                  </Modal>
                </Col>
              </Row>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

// EditQuizQuestionForm.propTypes = {
//   onSubmit: PropTypes.func.isRequired,
// };

export default reduxForm({
  form: 'edit_question', // a unique identifier for this form
})(EditQuizQuestionForm);
