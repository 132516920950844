/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Progress,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';
import renderCheckBoxField from '../../../../../shared/components/form/CheckBox';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';
import { URL_QUIZ } from '../../../../../utils/EndPoints';

const CreateQuizForm = () => {
  const [progress, setProgress] = useState();
  const { id } = useParams();

  // loading
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('name', e.target.name.value);
    data.append('isFinalQuiz', e.target.isFinalQuiz.checked);
    data.append('topicId', id);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: () => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };

    setLoading(true);
    axios.post(URL_QUIZ, data, options)
      .then(() => {
        window.location.reload();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        message.error('Something went wrong!');
      });
  };
  return (
    <Col md={12} lg={12}>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <form className="form" onSubmit={onSubmit}>
            <span className="form__form-group-label">Title Exam</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component="input"
                  type="text"
                  placeholder="Title Exam"
                  required
                />
              </div>
            </div>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="isFinalQuiz"
                  component={renderCheckBoxField}
                  label="Is this exam for final examination? required for certification"
                />
              </div>
            </div>
            {loading && (
            <Col md={12} lg={12}>
              <Progress animated value={progress} />
            </Col>
            )}
            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'create_quiz', // a unique identifier for this form
})(CreateQuizForm);
