/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Progress,
} from 'reactstrap';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Spin, message } from 'antd';
import {
  LOCALSTORAGE_TOKEN,
  LOCALSTORAGE_USERDETAIL,
} from '../../../../../utils/Types';
import {
  URL_COURSE,
  URL_WISHLISTSTATS,
  URL_USERS,
} from '../../../../../utils/EndPoints';
import renderSelectField from '../../../../../shared/components/form/Select';

const CreateWishlistForm = () => {
  const history = useHistory();
  const [progress, setProgress] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  // list select option
  const [course, setCourse] = useState([]);
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  // loading
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsLoading1(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(URL_COURSE, options)
      .then((res) => {
        setCourse(
          res.data.map((course) => ({
            value: `${course.id}`,
            label: course.title,
          })),
        );
        setIsLoading(false);
      })
      .catch(() => {
        message.error('Something went wrong!');
        setIsLoading(false);
      });
    axios
      .get(
        `${URL_USERS}/`,
        options,
      )
      .then((res1) => {
        setUser(
          res1.data.map((user) => ({
            value: `${user.id}`,
            label: user.name,
          })),
        );
        setIsLoading1(false);
      })
      .catch(() => {
        message.error('Something went wrong!');
        setIsLoading1(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const userDetail = JSON.parse(
      localStorage.getItem(LOCALSTORAGE_USERDETAIL),
    );
    const data = new FormData(e.target);
    data.append('userId', userDetail.id);
    data.append('title', e.target.title.value);
    data.append('courseId', e.target.course.value);
    data.append('userId', e.target.user.value);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };

    setLoading(true);
    axios
      .post(URL_WISHLISTSTATS, data, options)
      .then(() => {
        messageApi.success('New Wishlist Successfully Created');
        history.push('/admin/wishlist');
      })
      .catch(() => {
        messageApi.error('New Wishlist Failed Created');
      });
  };

  return (
    <Col md={12} lg={12}>
      {contextHolder}
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Create New Wishlist</h5>
          </div>
          <form className="form" onSubmit={onSubmit}>
            <div className="form__form-group">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <span className="form__form-group-label">Course {isLoading ? <Spin /> : null}</span>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="course"
                        component={renderSelectField}
                        type="text"
                        options={course}
                        placeholder="Select Course"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <span className="form__form-group-label">User {isLoading1 ? <Spin /> : null}</span>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="user"
                        component={renderSelectField}
                        type="text"
                        placeholder="Select User"
                        options={user}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {loading && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

// CreateCourseForm.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
// };

export default reduxForm({
  form: 'create_wishlist', // a unique identifier for this form
})(CreateWishlistForm);
