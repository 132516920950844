/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import {
  Button,
  ButtonToolbar,
  Card, CardBody, Col,
} from 'reactstrap';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { message } from 'antd';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';
import { URL_QUIZ } from '../../../../../utils/EndPoints';
import EditQuizForm from './components/editQuizForm';

const EditQuiz = () => {
  const history = useHistory();
  const params = useParams();
  const [quiz, setQuiz] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(`${URL_QUIZ}${params.id}/`, options)
      .then((res) => {
        setQuiz(res.data);
      })
      .catch(() => {
        message.error('Something went wrong!');
      });
  }, []);

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <ButtonToolbar className="form__button-toolbar">
          <Button size="sm" color="danger" onClick={history.goBack}>
            Back
          </Button>
        </ButtonToolbar>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Update Exam {quiz?.name}</h5>
            {/* <h5 className="subhead">Use default tabs with classes
            <span className="red-text"> tabs--justify tabs--bordered-bottom</span>
          </h5> */}
          </div>
          <div className="tabs tabs--justify tabs--bordered-bottom">
            <EditQuizForm />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default EditQuiz;
