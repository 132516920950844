/* eslint-disable indent */
/* eslint-disable no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container, Row,
} from 'reactstrap';
import { message } from 'antd';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/Types';
import { URL_ORDER } from '../../../../utils/EndPoints';
import ListDataOrder from './components/listDataOrder';
import OrderListTable from './components/orderList';

const OrderList = () => {
  const [order, setOrder] = useState([]);
  const listDataTable = ListDataOrder(order);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(`${URL_ORDER}`, options)
      .then((res) => {
        setOrder(res.data);
        setLoading(false);
      })
      .catch((err) => {
        message.error('Something when wrong!');
        setLoading(false);
      });
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <OrderListTable
          listDataTable={listDataTable}
          isLoading={isLoading}
        />
      </Row>
    </Container>
  );
};
export default OrderList;
