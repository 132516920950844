/* eslint-disable max-len */

/* eslint-disable react/react-in-jsx-scope */
import
React,
{ useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const PhotoFormatter = (value) => (
  <div>
    <img
      src={value}
      alt=""
      style={{
        width: '100px', height: 'auto', marginRight: '6px', borderRadius: '5px',
      }}
    />
  </div>
);

const eLibraryDetail = (history, info) => {
  history.push({
    pathname: `/admin/hero-detail/${info.id}`,
    state: { data: info },
  });
};

const ListDataELibrary = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableSortBy: true,
      },
      {
        Header: 'Date',
        accessor: 'created_at',
        width: 20,
        disableSortBy: true,
      },
      {
        Header: 'Image',
        accessor: 'photo',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Image',
        accessor: 'image',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Action',
        accessor: 'status',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    for (let i = 0; i < dataAPI.length; i += 1) {
      data.push({
        id: dataAPI[i].id,
        number: `${i + 1}`,
        created_at: dataAPI[i]?.created_at,
        photo: PhotoFormatter(`${dataAPI[i].image}`),
        image: <a href={dataAPI[i]?.image} target="_blank" rel="noreferrer">{dataAPI[i]?.image?.slice(54)}</a>,
        status: [
          // eslint-disable-next-line no-loop-func
          // eslint-disable-next-line max-len
          (<Button key={dataAPI[i].id} color="outline-success" onClick={() => eLibraryDetail(history, dataAPI[i])}>Detail</Button>),
        ],
      });
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataELibrary;
