/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */

/* eslint-disable react/react-in-jsx-scope */
import
React,
{ useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

// const PhotoFormatter = (value) => (
//   <div className="products-list__img-wrap">
//     <img src={value} alt="" />
//   </div>
// );
const materialEdit = (history, info) => {
  history.push({
    pathname: `/admin/material-edit/${info.id}`,
    state: { data: info },
  });
};

const ListDataMaterial = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableSortBy: true,
      },
      {
        Header: 'Title Material',
        accessor: 'name',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Modul',
        accessor: 'modul',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Video',
        accessor: 'video',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    for (let i = 0; i < dataAPI.length; i += 1) {
      data.push({
        id: dataAPI[i]?.id,
        number: `${dataAPI[i]?.order}`,
        name: dataAPI[i]?.name,
        modul: dataAPI[i]?.modul ? <a href={dataAPI[i]?.modul} target="_blank" rel="noreferrer">Link Modul</a> : '-',
        video: dataAPI[i]?.video ? <a href={dataAPI[i]?.video} target="_blank" rel="noreferrer">Link Video</a> : '-',
        action: [
          // eslint-disable-next-line no-loop-func
          // eslint-disable-next-line max-len
          (<Button key={Math.random()} color="outline-success" onClick={() => materialEdit(history, dataAPI[i])}>Detail</Button>),
        ],
      });
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataMaterial;
