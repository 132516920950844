/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
// import ReactExport from 'react-export-excel';
import {
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import TopicListTable from './TopicList';
import ListDataTopic from './ListDataTopic';
import {
  URL_INSTRUCTOR_BY_IDCOURSE,
  URL_STUDENT_LIST_CUSTOM,
  URL_TOPIC_BY_IDCOURSE,
} from '../../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';
import StudentListTable from '../../StudentList/components/studentList';
import ListDataStudentList from '../../StudentList/components/listDataStudentList';
import InstructorListTable from '../../Instructor/components/instructorList';
import ListDataInstructorList from '../../Instructor/components/listDataInstructor';

const CourseTopicContent = () => {
  // const { ExcelFile } = ReactExport.ExcelFile;
  // const { ExcelSheet } = ReactExport.ExcelFile.ExcelSheet;
  // const { ExcelColumn } = ReactExport.ExcelFile.ExcelColumn;

  const { id } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const tabs = queryParams.get('tabs');
  // const [activeTab, setActiveTab] = useState(tabs);
  // const toggle = (tab) => {
  //   if (activeTab !== tab) setActiveTab(tab);
  // };

  // list
  const [topics, setTopics] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [instructor, setInstructor] = useState([]);

  const listDataTable = ListDataTopic(topics);
  const listDataTable2 = ListDataStudentList(studentList);
  const listDataTable3 = ListDataInstructorList(instructor);

  // loading
  const [loadingTopic, setLoadingTopic] = useState(false);
  const [loadingStudentList, setLoadingStudentList] = useState(false);
  const [loadingInstructorList, setLoadingInstructorList] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };

    // get topic
    setLoadingTopic(true);
    axios.get(`${URL_TOPIC_BY_IDCOURSE}${id}/`, options)
      .then((res) => {
        setTopics(res.data);
        setLoadingTopic(false);
      })
      .catch(() => {
        setLoadingTopic(false);
      });

    // get student list
    setLoadingStudentList(true);
    axios.get(`${URL_STUDENT_LIST_CUSTOM}${id}/`, options)
      .then((res) => {
        setStudentList(res.data);
        setLoadingStudentList(false);
      })
      .catch(() => {
        setLoadingStudentList(false);
      });

    // get instructor list
    setLoadingInstructorList(true);
    axios.get(`${URL_INSTRUCTOR_BY_IDCOURSE}${id}`, options)
      .then((res) => {
        setInstructor(res.data);
        setLoadingInstructorList(false);
      })
      .catch(() => {
        setLoadingInstructorList(false);
      });
  }, []);

  return (
    <div className="tabs__wrap">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: tabs === '1' })}
            href={`/admin/course-curriculum/${id}?tabs=1`}
            // onClick={() => toggle('1')}
          >
            Curriculum
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: tabs === '2' })}
            href={`/admin/course-curriculum/${id}?tabs=2`}
            // onClick={() => toggle('2')}
          >
            Attendance
          </NavLink>
        </NavItem> */}
        <NavItem>
          <NavLink
            className={classnames({ active: tabs === '3' })}
            href={`/admin/course-curriculum/${id}?tabs=3`}
            // onClick={() => toggle('3')}
          >
            Participant
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: tabs === '4' })}
            href={`/admin/course-curriculum/${id}?tabs=4`}
            // onClick={() => toggle('4')}
          >
            Instructor
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: tabs === '3' })}
            href={`/admin/course-curriculum/${id}?tabs=3`}
            // onClick={() => toggle('3')}
          >
            Live Session
          </NavLink>
        </NavItem> */}
        {/* <NavItem>
          <NavLink
            className={classnames({ active: tabs === '3' })}
            href={`/admin/course-curriculum/${id}?tabs=3`}
            // onClick={() => toggle('3')}
          >
            Offers
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: tabs === '4' })}
            href={`/admin/course-curriculum/${id}?tabs=4`}
            // onClick={() => toggle('4')}
          >
            Refounds
          </NavLink>
        </NavItem> */}
      </Nav>
      <TabContent activeTab={tabs}>
        <TabPane tabId="1">
          <TopicListTable
            listDataTable={listDataTable}
            isLoading={loadingTopic}
          />
        </TabPane>
        {/* <ExcelFile>
          <ExcelSheet data={attendance} name="Attendance">
            <ExcelColumn label="Tanggal" value="createdAt" />
            <ExcelColumn label="NIP" value="user_detail.nip" />
            <ExcelColumn label="Nama" value="user_detail.name" />
            <ExcelColumn label="Status" value="status" />
          </ExcelSheet>
        </ExcelFile> */}
        <TabPane tabId="3">
          <StudentListTable
            listDataTable={listDataTable2}
            isLoading={loadingStudentList}
          />
        </TabPane>
        <TabPane tabId="4">
          <InstructorListTable
            listDataTable={listDataTable3}
            isLoading={loadingInstructorList}
          />
        </TabPane>
        {/* <TabPane tabId="3">
          <LiveSessionListTable
            listDataTable={listDataTable2}
            isLoading={loadingSession}
          />
        </TabPane> */}
      </TabContent>
    </div>
  );
};

export default CourseTopicContent;
