/* eslint-disable max-len */
/* eslint-disable no-plusplus */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import axios from 'axios';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import { Card, CardBody, Col } from 'reactstrap';
import { Spin, message } from 'antd';
import getTooltipStyles from '../../../../shared/helpers';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/Types';
import { URL_PAIDORDERSTATS } from '../../../../utils/EndPoints';
import renderSelectField from '../../../../shared/components/form/Select';

function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const Chart = ({ themeName }) => {
  const years = [];
  const firstYear = 2015;
  const thisYear = moment().year();
  const [historyLogin, setHistoryLogin] = useState([]);
  const [loading, setLoading] = useState(false);
  for (let i = 0; i <= (thisYear - firstYear); i++) {
    years.push({
      value: (firstYear + i).toString(),
      label: (firstYear + i).toString(),
    });
  }

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(URL_PAIDORDERSTATS, options)
      .then((res) => {
        setHistoryLogin(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div>
            <div className="card__title d-flex justify-content-between align-items-center flex-wrap">
              <h5 className="bold-text">Total Revenue Chart</h5>
              <div style={{ width: '100px' }}>
                <Field
                  name="years"
                  component={renderSelectField}
                  type="text"
                  placeholder="Year"
                  options={years.sort((a, b) => b.value - a.value)}
                  rules={{ required: true }}
                  onChange={(e) => {
                    const year = e.value;
                    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                    const options = {
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${token}`,
                      },
                    };

                    axios.get(`${URL_PAIDORDERSTATS}?year=${year}`, options)
                      .then((res) => {
                        setHistoryLogin(res.data);
                      })
                      .catch(() => {
                        message.error('Something went wrong!');
                      });
                  }}
                />
              </div>
            </div>
            {
              loading
                ? <Spin />

                : (
                  <ResponsiveContainer height={250} className="dashboard__area">
                    <AreaChart data={historyLogin !== null && historyLogin} margin={{ top: 20, left: -15, bottom: 20 }}>
                      <XAxis dataKey="month" tickLine={false} />
                      <YAxis tickLine={false} orientation="right" tickFormatter={formatNumberWithCommas} />
                      <Tooltip
                        {...getTooltipStyles(themeName, 'defaultItems')}
                        formatter={(value) => `$${formatNumberWithCommas(value)}`}
                      />
                      <Legend />
                      <CartesianGrid />
                      <Area name="Revenue" type="monotone" dataKey="paid_order" fill="blue" stroke="blue" fillOpacity={0.2} />
                    </AreaChart>
                  </ResponsiveContainer>
                )
            }
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
Chart.propTypes = {
  themeName: PropTypes.string.isRequired,
};

export default reduxForm({
  form: 'Revenue', // a unique identifier for this form
})(connect((state) => ({ themeName: state.theme.className }))(Chart));
