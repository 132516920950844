/* eslint-disable no-shadow */

import React from 'react';
import {
  Container,
} from 'reactstrap';
import CreateHeroForm from './components/CreateHeroForm';

const CreateHero = () => (
  <Container className="dashboard">
    <CreateHeroForm />
  </Container>
);
export default CreateHero;
