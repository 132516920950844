/* eslint-disable no-shadow */

import React from 'react';
import {
  Container,
} from 'reactstrap';
import ELibraryForm from './components/EditHeroForm';

const EditHero = () => (
  <Container className="dashboard">
    <ELibraryForm />
  </Container>
);
export default EditHero;
