/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import {
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import MaterialListTable from './materialList';
import {
  URL_MATERIAL_BY_IDTOPIC,
  URL_QUIZ_BY_IDTOPIC,
  URL_LIVE_SESSION_BY_IDTOPIC,
} from '../../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';
import ListDataMaterial from './ListDataMaterial';
import ListDataQuiz from '../../Quiz/components/listDataQuiz';
import QuizListTable from '../../Quiz/components/quizList';
import LiveSessionListTable from '../../LiveSession/components/liveSessionList';
import ListDataLiveSession from '../../LiveSession/components/listDataLiveSession';

const CourseMaterialContent = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const tabs = queryParams.get('tabs');

  // const [activeTab, setActiveTab] = useState('1');

  // const toggle = (tab) => {
  //   if (activeTab !== tab) setActiveTab(tab);
  // };

  const params = useParams();
  const { id } = useParams();

  // list
  const [material, setMaterial] = useState([]);
  const [quiz, setQuiz] = useState([]);
  const [session, setSession] = useState([]);

  const listDataTable = ListDataMaterial(material);
  const listDataTable1 = ListDataQuiz(quiz);
  const listDataTable2 = ListDataLiveSession(session);

  // loading
  const [loadingMaterial, setLoadingMaterial] = useState(false);
  const [loadingQuiz, setLoadingQuiz] = useState(false);
  const [loadingSession, setLoadingSession] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };

    // get material
    setLoadingMaterial(true);
    axios.get(`${URL_MATERIAL_BY_IDTOPIC}${params.id}/`, options)
      .then((res) => {
        setMaterial(res.data);
        setLoadingMaterial(false);
      })
      .catch(() => {
        setLoadingMaterial(false);
      });

    // get quiz
    setLoadingQuiz(true);
    axios.get(`${URL_QUIZ_BY_IDTOPIC}${params.id}/`, options)
      .then((res) => {
        setQuiz(res.data);
        setLoadingQuiz(false);
      })
      .catch(() => {
        setLoadingQuiz(false);
      });
    // get live session
    setLoadingSession(true);
    axios.get(`${URL_LIVE_SESSION_BY_IDTOPIC}${id}/`, options)
      .then((res) => {
        setSession(res.data);
        setLoadingSession(false);
      })
      .catch(() => {
        setLoadingSession(false);
      });
  }, []);

  return (
    <div className="tabs__wrap">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: tabs === '1' })}
            href={`/admin/course-material/${id}?tabs=1`}
            // onClick={() => toggle('1')}
          >
            Material
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: tabs === '2' })}
            href={`/admin/course-material/${id}?tabs=2`}
            // onClick={() => toggle('2')}
          >
            Exam
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: tabs === '3' })}
            href={`/admin/course-material/${id}?tabs=3`}
            // onClick={() => toggle('3')}
          >
            Live Session
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={tabs}>
        <TabPane tabId="1">
          <MaterialListTable
            listDataTable={listDataTable}
            isLoading={loadingMaterial}
          />
        </TabPane>
        <TabPane tabId="2">
          <QuizListTable
            listDataTable={listDataTable1}
            isLoading={loadingQuiz}
          />
        </TabPane>
        <TabPane tabId="3">
          <LiveSessionListTable
            listDataTable={listDataTable2}
            isLoading={loadingSession}
          />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default CourseMaterialContent;
