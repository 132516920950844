/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { ButtonToolbar, Button } from 'reactstrap';
import { CurrentUserProps, ContactsProps } from '../../../../shared/prop-types/ChatProps';
import ChatContact from './ChatContact';
import ChatBubble from './ChatBubble';
import ChatTopbar from './ChatTopbar';

const Chat = ({ currentUser, forum }) => {
  const [currentMessages, setCurrentMessages] = useState(forum[0]?.discussion_list);
  const [currentChat, setCurrentChat] = useState(forum[0]?.user_detail.name);
  const [openContacts, setOpenContacts] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [forumQuestion, setForumQuestion] = useState(null);
  const history = useHistory();

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredForum = forum.filter(
    (item) => item?.user_detail?.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const chatClass = classNames({
    chat: true,
    'chat--open': openContacts,
  });

  const contactsClass = classNames({
    'chat__contact-list': true,
    'chat__contact-list--open': openContacts,
  });

  const onOpenChat = (contact, currentUser1) => {
    const dialog = forum.find((c) => c?.id === contact)?.discussion_list;
    const messages = dialog || null;
    setCurrentChat(currentUser1);
    setCurrentMessages(messages);
  };

  const onOpenContacts = () => {
    setOpenContacts(!openContacts);
  };

  return (
    <div
      className={chatClass}
      onClick={openContacts ? onOpenContacts : null}
      role="presentation"
    >
      <div className={contactsClass}>
        <ButtonToolbar className="form__button-toolbar">
          <Button size="sm" color="danger" onClick={history.goBack}>
            Back
          </Button>
        </ButtonToolbar>
        <div className="chat__search">
          <input
            className="chat__search-input"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearch}
          />
          <MagnifyIcon />
        </div>
        <div className="chat__contacts">
          <Scrollbar className="scroll chat__contacts-scroll" alwaysShowTracks>
            {filteredForum?.map((item) => (
              <button
                key={item.id}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  onOpenChat(item?.id, item?.user_detail?.name);
                  setForumQuestion(item.description);
                }}
              >
                <ChatContact active={item?.user_detail?.name === currentChat} contact={item} />
              </button>
            ))}
            {filteredForum.length === 0 && (
              <div className="chat__dialog-messages">
                <div className="chat__dialog-messages-empty">
                  <p>No Forum Available</p>
                </div>
              </div>
            )}
          </Scrollbar>
        </div>
      </div>
      {currentChat === null ? (
        <div className="chat__dialog">
          <ChatTopbar onClick={onOpenContacts} />
          <div className="chat__dialog-select-message">
            <p>Select a chat to start messaging</p>
          </div>
        </div>
      ) : (
        <div className="chat__dialog">
          <ChatTopbar
            contact={forum.find((item) => item?.user_detail?.name === currentChat)}
            onClick={onOpenContacts}
          />
          <Scrollbar className="scroll chat__scroll" alwaysShowTracks>
            <div className="chat__dialog-messages-wrap">
              {currentMessages === null || currentMessages?.length === 0 ? (
                <div className="chat__dialog-messages">
                  <div className="chat__dialog-messages-empty">
                    <p>No reply</p>
                  </div>
                </div>
              ) : (
                <div className="chat__dialog-messages">
                  <div style={{ fontSize: '20px' }}>{forumQuestion ?? ''}</div>
                  {currentMessages?.map((item) => (
                    <ChatBubble
                      key={item.id}
                      contact={
                        item?.user_detail?.name === currentUser.userName
                          ? currentUser
                          : forum.find((c) => c.item?.user_detail?.name === item.item?.user_detail?.name)
                      }
                      message={item}
                      active={item?.user_detail?.name === currentUser.userName}
                    />
                  ))}
                </div>
              )}
            </div>
          </Scrollbar>
        </div>
      )}
    </div>
  );
};

Chat.propTypes = {
  currentUser: CurrentUserProps.isRequired,
  forum: ContactsProps.isRequired,
};

export default Chat;
