/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  PieChart, Pie, Tooltip, ResponsiveContainer, Legend, Cell,
} from 'recharts';
import axios from 'axios';
import { Card, CardBody, Col } from 'reactstrap';
import { Spin } from 'antd';
import getTooltipStyles from '../../../../shared/helpers';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/Types';
import { URL_USERBYCOUNTRYSTATS } from '../../../../utils/EndPoints';

function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const colorArray = ['#FF0000 ', '#FEDF00', '#EF3340', '#2D2A4A', '#0038A8', '#DA251D', '#FFD100', '#002868', '#34B233', '#032EA1'];

// eslint-disable-next-line react/prop-types
const Chart = ({ themeName, dir }) => {
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const [historyLogin, setHistoryLogin] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(URL_USERBYCOUNTRYSTATS, options)
      .then((res) => {
        setHistoryLogin(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const renderLegend = () => (
    <ul className="dashboard__chart-legend">
      {historyLogin.map((entry, index) => (
        <li key={`legend-${index}`}>
          <span style={{ backgroundColor: colorArray[index % colorArray.length] }} />
          {entry.country}
        </li>
      ))}
    </ul>
  );
  const onMouseMove = (e) => {
    if (e.tooltipPosition) {
      setCoordinates({ x: dir === 'ltr' ? e.tooltipPosition.x : e.tooltipPosition.x / 10, y: e.tooltipPosition.y });
    }
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div>
            <div className="card__title d-flex justify-content-between align-items-center flex-wrap">
              <h5 className="bold-text">User By Country Stats</h5>
            </div>
            {
              loading ? <Spin />
                : (
                  <ResponsiveContainer height={450} className="dashboard__area">
                    <PieChart>
                      <Tooltip position={coordinates} {...getTooltipStyles(themeName)} formatter={(value) => `${formatNumberWithCommas(value)}`} />
                      <Pie
                        md={6}
                        lg={6}
                        data={historyLogin !== null && historyLogin}
                        dataKey="count"
                        nameKey="country"
                        label
                        innerRadius={50}
                        outerRadius={80}
                        onMouseMove={onMouseMove}
                      >
                        {historyLogin.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={colorArray[index % colorArray.length]} />
                        ))}
                      </Pie>
                      <Legend md={6} lg={6} layout="horizontal" verticalAlign="bottom" content={renderLegend} />
                    </PieChart>
                  </ResponsiveContainer>
                )
            }
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

Chart.propTypes = {
  themeName: PropTypes.string.isRequired,
};

export default connect((state) => ({ themeName: state.theme.className }))(Chart);
