/* eslint-disable func-names */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useRef } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Progress,
} from 'reactstrap';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import {
  LOCALSTORAGE_TOKEN,
} from '../../../../../utils/Types';
import {
  URL_FAQ,
} from '../../../../../utils/EndPoints';

const CreateCourseForm = () => {
  const history = useHistory();
  const [progress, setProgress] = useState(null);
  const editorRef = useRef(null);
  const [messageApi, contextHolder] = message.useMessage();

  // loading
  const [loading, setLoading] = useState(false);

  // Tinymce Editor
  const [content, setContents] = useState('');
  const handleEditorChange = (contents) => {
    setContents(contents);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data1 = new FormData(e.target);
    data1.append('question', e.target.title.value);
    data1.append('description', content);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data2) => {
        setProgress(Math.round((100 * data2.loaded) / data2.total));
      },
    };

    setLoading(true);
    axios
      .post(URL_FAQ, data1, options)
      .then(() => {
        messageApi.open({
          type: 'success',
          content: 'FAQ successfully created.',
        });
        history.push('/admin/faq');
        setLoading(false);
      })
      .catch(() => {
        messageApi.open({
          type: 'error',
          content: 'FAQ failed created.',
        });
        setLoading(false);
      });
  };

  return (
    <Col md={12} lg={12}>
      {contextHolder}
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Create New FAQ</h5>
          </div>
          <form className="form" onSubmit={onSubmit}>
            <span className="form__form-group-label">Title</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="title"
                  component="input"
                  type="text"
                  placeholder="Input Course Title"
                  required
                />
              </div>
            </div>

            <span className="form__form-group-label">Description</span>
            <div className="form__form-group">
              <div className="form__form-group-field w-100-role-application">
                <input
                  id="my-file"
                  type="file"
                  name="my-file"
                  style={{ display: 'none' }}
                />
                <Editor
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  apiKey="kdsr6jcmxjffjg6z20pb4vtbg0fegwqdqy6ltsxoahuo3dvg"
                  init={{
                    skin: 'snow',
                    icons: 'thin',
                    height: 500,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen textcolor ',
                      'insertdatetime media table paste code help wordcount',
                      'image code',
                    ],
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    textcolor_rows: '4',
                    file_picker_callback(callback, value, meta) {
                      if (meta.filetype === 'image') {
                        const input = document.getElementById('my-file');
                        input.click();
                        input.onchange = function () {
                          const file = input.files[0];
                          const reader = new FileReader();
                          reader.onload = function (e) {
                            callback(e.target.result, {
                              alt: file.name,
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                      }
                    },

                    content_style:
                      "@import url('http://fonts.gstatic.com/s/nunito/v7/1TiHc9yag0wq3lDO9cw0vpBw1xU1rKptJj_0jans920.woff2');",
                    font_formats: 'Nunito',

                    toolbar:
                      'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
                  }}
                  onEditorChange={handleEditorChange}
                  outputFormat="html"
                />
              </div>
            </div>

            {loading && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'create_course', // a unique identifier for this form
})(CreateCourseForm);
