/* eslint-disable max-len */

/* eslint-disable react/react-in-jsx-scope */
import
React,
{ useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

// const PhotoFormatter = (value) => (
//   <div className="products-list__img-wrap">
//     <img src={value} alt="" />
//   </div>
// );
const quizEdit = (history, info) => {
  history.push({
    pathname: `/admin/quiz-edit/${info.id}`,
    state: { data: info },
  });
};

const quizQuestion = (history, info) => {
  history.push({
    pathname: `/admin/quiz-question/${info.id}`,
    state: { data: info },
  });
};

const quizScore = (history, info) => {
  history.push({
    pathname: `/admin/quiz-score/${info.id}`,
    state: { data: info },
  });
};

const ListDataQuiz = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableSortBy: true,
      },
      {
        Header: 'Title Exam',
        accessor: 'name',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Final Exam',
        accessor: 'isFinalQuiz',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    for (let i = 0; i < dataAPI.length; i += 1) {
      data.push({
        id: dataAPI[i].id,
        number: `${i + 1}`,
        name: dataAPI[i].name,
        isFinalQuiz: `${dataAPI[i].isFinalQuiz === true ? '✔' : '✖'}`,
        action: [
          // eslint-disable-next-line no-loop-func
          // eslint-disable-next-line max-len
          (<Button key={Math.random()} color="outline-success" onClick={() => quizEdit(history, dataAPI[i])}>Detail</Button>),
          (<Button key={Math.random()} color="outline-success" onClick={() => quizQuestion(history, dataAPI[i])}>Question</Button>),
          (<Button key={Math.random()} color="outline-success" onClick={() => quizScore(history, dataAPI[i])}>Score</Button>),
        ],
      });
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataQuiz;
