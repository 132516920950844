/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Card, Col, CardBody } from 'reactstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Spin, message } from 'antd';
import Chat from './Chat';
import { URL_GET_FORUM_BYCOURSE } from '../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/Types';

const ChatCard = () => {
  const [forum, setForum] = useState([]);
  const params = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(URL_GET_FORUM_BYCOURSE + params.id, options).then((res) => {
      setForum(res.data);
      setLoading(false);
    }).catch(() => {
      messageApi.info('Sorry, Something went wrong!');
      setLoading(false);
    });
  }, []);
  return (
    <Col md={12} lg={12}>
      {contextHolder}
      <Card>
        <CardBody>
          {
            loading
              ? <Spin />
              : (
                <Chat
                  forum={forum}
                  currentUser={{
                    avatar: `${process.env.PUBLIC_URL}/img/ava.png`,
                    name: 'Roman Johanson',
                    userName: 'dragonKing',
                  }}
                />
              )
          }
        </CardBody>
      </Card>
    </Col>
  );
};

export default ChatCard;
