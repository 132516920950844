/* eslint-disable max-len */

/* eslint-disable react/react-in-jsx-scope */
import
React,
{ useMemo } from 'react';
import { Button } from 'reactstrap';
import DOMPurify from 'dompurify';
import { useHistory } from 'react-router-dom';
// const PhotoFormatter = (value) => (
//   <div className="products-list__img-wrap">
//     <img src={value} alt="" />
//   </div>
// );
const questionEdit = (history, info) => {
  history.push({
    pathname: `/admin/quiz-question-edit/${info.id}`,
    state: { data: info },
  });
};

const ListDataQuestion = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableSortBy: true,
      },
      {
        Header: 'Question',
        accessor: 'question_text',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Correct Answer',
        accessor: 'correct_answer',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Weight',
        accessor: 'weight',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    for (let i = 0; i < dataAPI.length; i += 1) {
      data.push({
        id: dataAPI[i].id,
        number: `${i + 1}`,
        // eslint-disable-next-line react/no-danger
        question_text: <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dataAPI[i]?.question_text) }} />,
        correct_answer: dataAPI[i]?.correct_answer,
        weight: `${dataAPI[i]?.weight_correct}`,
        action: [
          // eslint-disable-next-line no-loop-func
          // eslint-disable-next-line max-len
          (<Button key={Math.random()} color="outline-success" onClick={() => questionEdit(history, dataAPI[i])}>Detail</Button>),
        ],
      });
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataQuestion;
