/* eslint-disable no-shadow */
import React from 'react';
import {
  Container,
} from 'reactstrap';
import MyAccountForm from './components/myAccountForm';

const MyAccount = () => (
  <Container className="dashboard">
    <MyAccountForm />
  </Container>
);
export default MyAccount;
