/* eslint-disable no-shadow */
import React from 'react';
import {
  Container,
  ButtonToolbar,
  Button,
  CardBody,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import CreateCourseForm from './components/courseForm';

const CreateCourse = () => {
  const history = useHistory();
  // const [isLoading, setLoading] = useState(false);
  return (
    <Container className="dashboard">
      <ButtonToolbar className="form__button-toolbar">
        <Button size="sm" color="danger" onClick={history.goBack}>
          Back
        </Button>
      </ButtonToolbar>
      <CardBody>
        <CreateCourseForm />
      </CardBody>
    </Container>
  );
};

export default CreateCourse;
