/* eslint-disable no-shadow */

import React from 'react';
import {
  Container,
} from 'reactstrap';
import PartnerForm from './components/PartnerForm';

const CreatePartner = () => (
  <Container className="dashboard">
    <PartnerForm />
  </Container>
);
export default CreatePartner;
