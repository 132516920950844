/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress,
} from 'reactstrap';
import { message } from 'antd';
import { URL_LIVE_SESSION } from '../../../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../../../utils/Types';

const EditLiveSessionForm = (stateOri) => {
  const history = useHistory();
  const { id } = useParams();
  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState();

  const [isEditable, setEditable] = useState(false);
  const [confirm, setConfirm] = useState(0);
  const [modal, setModal] = useState(false);
  const toggle = (idSession) => {
    setConfirm(idSession);
    setModal(!modal);
  };
  useEffect(() => {
    initialize(data);
  }, []);

  return (
    <Col md={12} lg={12}>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <form
            className="form"
            onSubmit={(e) => {
              setLoading(true);
              e.preventDefault();
              const data = new FormData();
              data.append('title', e.target.title.value);
              data.append('date', e.target.date.value);
              data.append('time', e.target.time.value);
              data.append('link_meeting', e.target.link_meeting.value);
              data.append('passcode_meeting', e.target.passcode_meeting.value);
              const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
              const options = {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${token}`,
                },
                onUploadProgress: (data) => {
                  setProgress(Math.round((100 * data.loaded) / data.total));
                },
              };
              axios.patch(`${URL_LIVE_SESSION}${id}/`, data, options)
                .then((res) => {
                  history.push({
                    pathname: `/admin/course-material/${res.data.topicId}`,
                    search: '?tabs=3',
                  });
                  setLoading(false);
                })
                .catch(() => {
                  setLoading(false);
                  message.error('Something went wrong!');
                });
            }}
          >
            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">Title Session</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="title"
                    component="input"
                    type="text"
                    placeholder="Title session"
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Date Live Session</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="date"
                    component="input"
                    type="date"
                    placeholder="Choose date"
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Time Live Session</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="time"
                    component="input"
                    type="time"
                    placeholder="Choose time"
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">Link Meeting Live Session</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="link_meeting"
                    component="input"
                    type="text"
                    placeholder="Masukan Link Meeting"
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">Passcode Live Session</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="passcode_meeting"
                    component="input"
                    type="text"
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div>
            {loading && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}
            <ButtonToolbar className="form__button-toolbar">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setEditable(!isEditable)}
                color={isEditable ? 'danger' : 'primary'}
              >
                {!isEditable ? 'Change' : 'Cancel'}
              </button>
              {isEditable && (
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  {' '}
                  Save
                </button>
              )}
              {isEditable === false && (
                <button
                  type="button"
                  size="sm"
                  onClick={() => toggle(data?.id)}
                  className="btn btn-danger"
                >
                  Delete
                </button>
              )}
              <Modal
                toggle={toggle}
                isOpen={modal}
                backdrop={false}
              >
                <ModalHeader toggle={toggle}>
                  Delete Session
                </ModalHeader>
                <ModalBody>
                  Are you sure you want to Delete this Session?
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                      const options = {
                        headers: {
                          'Content-Type':
                            'application/json',
                          Authorization: `Token ${token}`,
                        },
                      };
                      axios
                        .delete(
                          `${URL_LIVE_SESSION}${confirm}`,
                          options,
                        )
                        .then(() => {
                          history.push({
                            pathname: `/admin/course-material/${data.topicId}`,
                            search: '?tabs=3',
                          });
                        })
                        .catch(() => {
                          message.error('Something went wrong!');
                        });
                    }}
                  >
                    Delete
                  </Button>
                </ModalFooter>
              </Modal>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'edit_live_session', // a unique identifier for this form
  enableReinitialize: true,
})(EditLiveSessionForm);
