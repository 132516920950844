/* eslint-disable indent */
/* eslint-disable no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container, Row,
} from 'reactstrap';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/Types';
import { URL_HEROBANNER } from '../../../../utils/EndPoints';
import HeroListingTable from './components/HeroListing';
import ListDataELibrary from './components/listData';

const HeroListing = () => {
  const [eLibrary, seteLibrary] = useState([]);
  const listDataTable = ListDataELibrary(eLibrary);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(URL_HEROBANNER, options)
      .then((res) => {
        seteLibrary(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <HeroListingTable
          listDataTable={listDataTable}
          isLoading={isLoading}
        />
      </Row>
    </Container>
  );
};
export default HeroListing;
