/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container, Row,
} from 'reactstrap';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/Types';
import { URL_WISHLISTSTATS } from '../../../../utils/EndPoints';
import ListDataWishlist from './components/ListData';
import WishlistListingTable from './components/WishlistListing';

const WishlistListing = () => {
  const [wishlist, setWishlist] = useState([]);
  const listDataTable = ListDataWishlist(wishlist);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(URL_WISHLISTSTATS, options)
      .then((res) => {
        setWishlist(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <WishlistListingTable
          listDataTable={listDataTable}
          isLoading={isLoading}
        />
      </Row>
    </Container>
  );
};
export default WishlistListing;
