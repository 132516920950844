/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
import
React,
{ useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const courseDetail = (history, info) => {
  history.push({
    pathname: `/admin/course-detail/${info.id}`,
    state: { data: info },
  });
};

const Topic = (history, info) => {
  history.push({
    pathname: `/admin/course-curriculum/${info.id}`,
    search: '?tabs=1',
    state: { data: info },
  });
};

const Forum = (history, info) => {
  history.push({
    pathname: `/admin/forum/${info.id}`,
    state: { data: info },
  });
};

const ListData = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableSortBy: true,
      },
      {
        Header: 'Date',
        accessor: 'created_at',
        width: 20,
        disableSortBy: true,
      },
      {
        Header: 'Title',
        accessor: 'title',
        width: 110,
      },
      {
        Header: 'Code',
        accessor: 'code',
        width: 110,
      },
      {
        Header: 'Topic',
        accessor: 'topic',
        width: 110,
      },
      {
        Header: 'Category',
        accessor: 'category',
        width: 110,
      },
      {
        Header: 'Release',
        accessor: 'is_publish',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Action',
        accessor: 'status',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    for (let i = 0; i < dataAPI.length; i += 1) {
      data.push({
        id: dataAPI[i].id,
        number: `${i + 1}`,
        created_at: dataAPI[i].created_at,
        title: dataAPI[i].title,
        code: dataAPI[i].course_code,
        topic: dataAPI[i]?.category_detail?.name ?? '',
        category: dataAPI[i]?.type_detail?.name ?? '',
        is_publish: <div className="course-show-in-homepage">{dataAPI[i].isPublish === false ? '✘' : '✔'}</div>,
        status: [
          // eslint-disable-next-line no-loop-func
          // eslint-disable-next-line max-len
          (<Button key={Math.random()} color="outline-success" onClick={() => courseDetail(history, dataAPI[i])}>Detail</Button>),
          (<Button key={Math.random()} color="outline-success" onClick={() => Topic(history, dataAPI[i])}>Curriculum</Button>),
          (<Button key={Math.random()} color="outline-success" onClick={() => Forum(history, dataAPI[i])}>Forum</Button>),
        ],
      });
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListData;
