/* eslint-disable no-shadow */
import React from 'react';
import {
  Container,
} from 'reactstrap';
import CreateCategoryForm from './components/CreateCategoryForm';

const CreateCategory = () => (
  <Container className="dashboard">
    <CreateCategoryForm />
  </Container>
);
export default CreateCategory;
