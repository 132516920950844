/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import {
  Button,
  ButtonToolbar,
  Card, CardBody, Col,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
// import { LOCALSTORAGE_TOKEN } from '../../../../../../utils/Types';
// import { URL_QUESTION } from '../../../../../../utils/EndPoints';
import EditQuizQuestionForm from './components/editQuizQuestionForm';

const EditQuizQuestion = () => {
  const history = useHistory();

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <ButtonToolbar className="form__button-toolbar">
          <Button size="sm" color="danger" onClick={history.goBack}>
            Back
          </Button>
        </ButtonToolbar>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Update Question</h5>
            {/* <h5 className="subhead">Use default tabs with classes
            <span className="red-text"> tabs--justify tabs--bordered-bottom</span>
          </h5> */}
          </div>
          <div className="tabs tabs--justify tabs--bordered-bottom">
            <EditQuizQuestionForm />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default EditQuizQuestion;
