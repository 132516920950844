/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container, Row,
} from 'reactstrap';
import { message } from 'antd';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/Types';
import { URL_USERS } from '../../../../utils/EndPoints';
import ListDataUser from './components/listDataUser';
import UserListTable from './components/userList';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(`${URL_USERS}`, options)
      .then((res) => {
        const cleanedData = res.data.map((user) => ({
          ...user,
          created_at: user.created_at.replace(' | ', 'T'),
        }));
        const sortedUsers = cleanedData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        const editData = sortedUsers.map((user) => ({
          ...user,
          created_at: user.created_at.replace('T', ' | '),
        }));
        setUsers(editData);
        setLoading(false);
      })
      .catch((err) => {
        message.error('Something when wrong!');
        setLoading(false);
      });
  }, []);

  const listDataTable = ListDataUser(users);

  return (
    <Container className="dashboard">
      <Row>
        <UserListTable
          listDataTable={listDataTable}
          isLoading={isLoading}
        />
      </Row>
    </Container>
  );
};

export default UserList;
