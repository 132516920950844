/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Progress,
} from 'reactstrap';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import {
  LOCALSTORAGE_TOKEN,
} from '../../../../../utils/Types';
import renderFileInputField from '../../../../../shared/components/form/FileInput';
import {
  URL_PARTNERSHIP,
} from '../../../../../utils/EndPoints';

const CreatePartnerForm = () => {
  const history = useHistory();
  const [progress, setProgress] = useState(null);
  const [image, setImage] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    if (e.target.image.files[0]) {
      data.append('image', e.target.image.files[0]);
    }
    data.append('name', e.target.name.value);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };
    axios
      .post(URL_PARTNERSHIP, data, options)
      .then(() => {
        messageApi.success('New Partners Successfully Created');
        history.push('/admin/partners');
      })
      .catch(() => {
        messageApi.error('New Partners Failed Created');
      });
  };

  const imageHandler = () => {
    if (image) {
      return image;
    }
    return '/img/no_image.png';
  };

  const inputImageHandler = (e) => {
    setImage(URL.createObjectURL(e.file));
    const conditions = ['image/png', 'image/jpg', 'image/jpeg'];

    if (!conditions.some((el) => e.file.type.includes(el))) {
      messageApi.open({
        type: 'error',
        content: 'Only image file types allowed',
      });
    }
  };

  return (
    <Col md={12} lg={12}>
      {contextHolder}
      <ButtonToolbar className="form__button-toolbar">
        <Button size="sm" color="danger" onClick={history.goBack}>
          Back
        </Button>
      </ButtonToolbar>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Create New Partner</h5>
          </div>
          <form className="form" onSubmit={onSubmit}>
            <img src={imageHandler()} alt="" className="testimonial-image" />
            <span className="form__form-group-label">Image</span>
            <div className="form__form-group">
              <div className="form__form-group-field mb-0-label">
                <Field
                  name="image"
                  component={renderFileInputField}
                  required
                  onChange={(e) => { inputImageHandler(e); }}
                />
              </div>
            </div>
            <span className="form__form-group-label">Name</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component="input"
                  type="text"
                  placeholder="Name"
                  required
                />
              </div>
            </div>

            {progress !== null && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                type="submit"
              // disabled={!selectedStudent}
              >
                Submit
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'CreatePartner_form', // a unique identifier for this form
})(CreatePartnerForm);
