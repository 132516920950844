/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
import React, { useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const userDetail = (history, info) => {
  history.push({
    pathname: `/admin/user-detail/${info.id}`,
    state: { data: info },
  });
};

const ListDataUser = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Date',
        accessor: 'created_at',
        width: 20,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Email',
        accessor: 'email',
        width: 110,
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: 110,
      },
      {
        Header: 'Phone',
        accessor: 'phone',
        width: 110,
      },
      {
        Header: 'Role',
        accessor: 'role',
        width: 110,
      },
      {
        Header: 'Country',
        accessor: 'country',
        width: 110,
      },
      {
        Header: 'Action',
        accessor: 'status',
        width: 110,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ], [],
  );

  const data = dataAPI.map((user, index) => ({
    id: user.id,
    number: `${index + 1}`,
    created_at: user?.created_at,
    email: user?.email,
    name: user?.name,
    phone: user?.phone,
    role: user?.role,
    country: user?.country,
    status: (
      <Button key={user?.id} color="outline-success" onClick={() => userDetail(history, user)}>Detail</Button>
    ),
  }));

  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataUser;
