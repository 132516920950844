/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
import
React,
{ useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const PhotoFormatter = (value) => (
  <div>
    <img
      src={value}
      alt=""
      style={{
        width: '100px', height: 'auto', marginRight: '6px', borderRadius: '5px',
      }}
    />
  </div>
);

const wishlistDetail = (history, info) => {
  history.push({
    pathname: `/admin/wishlist-detail/${info.id}`,
    state: { data: info },
  });
};

const ListDataWishlist = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Date',
        accessor: 'created_at',
        width: 20,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Course Image',
        accessor: 'image',
        disableGlobalFilter: true,
        width: 110,
      },
      {
        Header: 'Course Title',
        accessor: 'title',
        width: 110,
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: 110,
      },
      {
        Header: 'Country',
        accessor: 'country',
        width: 110,
      },
      {
        Header: 'Phone',
        accessor: 'phone',
        width: 110,
      },
      {
        Header: 'Action',
        accessor: 'status',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    for (let i = 0; i < dataAPI.length; i += 1) {
      data.push({
        id: dataAPI[i].id,
        number: `${i + 1}`,
        created_at: dataAPI[i]?.created_at,
        name: dataAPI[i]?.user_detail?.name,
        country: dataAPI[i]?.user_detail?.country,
        phone: dataAPI[i]?.user_detail?.phone,
        title: dataAPI[i]?.course_detail?.title,
        image: PhotoFormatter(`${dataAPI[i]?.course_detail?.courses_small_img}`),
        status: [
          // eslint-disable-next-line no-loop-func
          // eslint-disable-next-line max-len
          (<Button key={dataAPI[i].id} color="outline-success" onClick={() => wishlistDetail(history, dataAPI[i])}>Detail</Button>),
        ],
      });
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataWishlist;
