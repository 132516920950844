/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Col,
  ButtonToolbar,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import axios from 'axios';
import { FaTrash } from 'react-icons/fa';
import { message } from 'antd';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/Types';
import { ContactProps } from '../../../../shared/prop-types/ChatProps';
import { URL_FORUM } from '../../../../utils/EndPoints';

const Ava = `${process.env.PUBLIC_URL}/img/no_image.png`;

const contactClass = (active) => classNames({
  chat__contact: true,
  'chat__contact--active': active,
});

const ChatUser = ({ contact, active }) => {
  const [loading, setLoading] = useState(false);
  let lastMessage = '';
  const [confirm, setConfirm] = useState(0);
  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    setConfirm(id);
    setModal(!modal);
  };
  if (contact?.description) {
    lastMessage = contact?.description;
  } else {
    lastMessage = 'new contact';
  }
  return (
    <div className={contactClass(active)}>
      <div className="chat__contact-avatar">
        <img src={contact?.user_detail?.photo ?? Ava} alt="ava" />
      </div>
      <div className="chat__contact-preview">
        <Row className="align-items-center">
          <Col xs={5} md={10}>
            <p className="chat__contact-name">{contact?.user_detail?.name}</p>
            <p className="chat__contact-post" style={{ fontSize: '9.5px' }}>
              {contact?.user_detail?.job} || {contact?.created_at}
            </p>
            <p className="chat__contact-last-message">{lastMessage}</p>
          </Col>
          <Col xs={1} md={1} className="d-flex justify-content-end">
            <ButtonToolbar className="form__button-toolbar">
              <button
                type="button"
                size="sm"
                onClick={() => toggle(contact?.id)}
              >
                <FaTrash color="red" />
              </button>
              <Modal
                toggle={toggle}
                isOpen={modal}
                backdrop={false}
              >
                <ModalHeader toggle={toggle}>
                  Delete Forum
                </ModalHeader>
                <ModalBody>
                  Are you sure you want to Delete this Forum?
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                      const options = {
                        headers: {
                          'Content-Type':
                                    'application/json',
                          Authorization: `Token ${token}`,
                        },
                      };
                      setLoading(true);
                      axios
                        .delete(
                          `${URL_FORUM}${confirm}`,
                          options,
                        )
                        .then(() => {
                          setLoading(false);
                          window.location.reload();
                        })
                        .catch(() => {
                          message.error('Something went wrong!');
                          setLoading(false);
                        });
                    }}
                    loading={loading}
                  >
                    Delete
                  </Button>
                </ModalFooter>
              </Modal>
            </ButtonToolbar>

          </Col>
        </Row>
      </div>
    </div>
  );
};

ChatUser.propTypes = {
  contact: ContactProps.isRequired,
  active: PropTypes.bool,
};

ChatUser.defaultProps = {
  active: false,
};

export default ChatUser;
