import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Button } from 'reactstrap';
// import renderCheckBoxField from '../../../shared/components/form/CheckBox';

const ResetPasswordForm = ({ handleSubmit }) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <span className="form__form-group-label">Email</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <AccountOutlineIcon />
        </div>
        <Field
          name="email"
          component="input"
          type="email"
          placeholder="email@example.com"
          required
        />
      </div>
    </div>
    <Button type="submit" color="primary" className="account__btn mt-4">Send</Button>
  </form>
);

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'reset_password_form',
})(ResetPasswordForm);
