/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Progress,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';
import { URL_LIVE_SESSION } from '../../../../../utils/EndPoints';

const CreateLiveSessionForm = () => {
  const { id } = useParams();
  const [progress, setProgress] = useState();

  // loading
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    // setLoading(true);
    e.preventDefault();
    const data = new FormData();
    data.append('title', e.target.title.value);
    data.append('date', e.target.date.value);
    data.append('time', e.target.time.value);
    data.append('link_meeting', e.target.link_meeting.value);
    data.append('passcode_meeting', e.target.passcode_meeting.value);
    data.append('topicId', id);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data1) => {
        setProgress(Math.round((100 * data1.loaded) / data1.total));
      },
    };
    setLoading(true);
    axios.post(URL_LIVE_SESSION, data, options)
      .then(() => {
        window.location.reload();
        setLoading(false);
      }).catch(() => {
        message.error('Something went wrong!');
      });
  };
  return (
    <Col md={12} lg={12}>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <form className="form" onSubmit={onSubmit}>
            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">Session</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="title"
                    component="input"
                    type="text"
                    placeholder="Title Session"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Date Session</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="date"
                    component="input"
                    type="date"
                    placeholder="Choose date"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Time Session</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="time"
                    component="input"
                    type="time"
                    placeholder="Choose time"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">Link Meeting Session</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="link_meeting"
                    component="input"
                    type="text"
                    placeholder="Link Meeting"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">Passcode Live Session</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="passcode_meeting"
                    component="input"
                    type="text"
                    placeholder="Passcode"
                    required
                  />
                </div>
              </div>
            </div>
            {loading && (
            <Col md={12} lg={12}>
              <Progress animated value={progress}>{progress}%</Progress>
            </Col>
            )}
            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'create_live_session', // a unique identifier for this form
})(CreateLiveSessionForm);
