/* eslint-disable max-len */

/* eslint-disable react/react-in-jsx-scope */
import React, { useMemo } from 'react';

const ListDataStudentList = (dataAPI) => {
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableSortBy: true,
      },
      {
        Header: 'Email',
        accessor: 'email',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Name',
        accessor: 'name',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Course Certificate',
        accessor: 'isCertificate',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    for (let i = 0; i < dataAPI.length; i += 1) {
      data.push({
        id: dataAPI[i].id,
        number: `${i + 1}`,
        email: `${dataAPI[i]?.email ?? '-'}`,
        name: `${dataAPI[i]?.name ?? '-'}`,
        isCertificate: <div className="course-topic-certificate">{dataAPI[i]?.isCertificate === false ? '✘' : '✔'}</div>,
      });
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataStudentList;
