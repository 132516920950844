import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import AccountTieIcon from 'mdi-react/AccountTieIcon';
import BookVariant from 'mdi-react/BookVariantIcon';
import MoneyIcon from 'mdi-react/MoneyIcon';
import axios from 'axios';
import DescriptionCard from './components/DescriptionCard';
import { LOCALSTORAGE_TOKEN } from '../../../utils/Types';
import {
  URL_COURSE,
  URL_USERBYROLE,
  URL_TOTALPAIDORDER,
  URL_INSTRUCTOR,
} from '../../../utils/EndPoints';
import ChartUser from './components/chartUser';
import ChartRevenue from './components/chartRevenue';
import EnrolledCart from './components/EnrolledCart';

function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const HomeAdmin = () => {
  const [course, setCourse] = useState([]);
  const [teacher, setTeacher] = useState([]);
  const [user, setUser] = useState([]);
  const [totalPaidOrder, setTotalPaidOrder] = useState([]);
  const [classIsLoading, setClassIsLoading] = useState(false);
  const [teacherIsLoading, setTeacherIsLoading] = useState(false);
  const [userIsLoading, setUserIsLoading] = useState(false);
  const [totalPaidOrderIsLoading, setTotalPaidOrderIsLoading] = useState(false);
  const [errorClass, setErrorClass] = useState('');
  const [errorTeacher, setErrorTeacher] = useState('');
  const [errorUser, setErrorUser] = useState('');
  const [errorTotalPaidOrder, setErrorTotalPaidOrder] = useState('');

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    setClassIsLoading(true);
    axios
      .get(URL_COURSE, options)
      .then((res) => {
        setCourse(res.data);
        setClassIsLoading(false);
      })
      .catch((err) => {
        setErrorClass(err.response.status);
        setClassIsLoading(false);
      });

    setUserIsLoading(true);
    axios
      .get(`${URL_USERBYROLE}User/`, options)
      .then((res) => {
        setUser(res.data);
        setUserIsLoading(false);
      })
      .catch((err) => {
        setErrorUser(err.response.status);
        setUserIsLoading(false);
      });

    setTeacherIsLoading(true);
    axios
      .get(URL_INSTRUCTOR, options)
      .then((res) => {
        setTeacher(res.data);
        setTeacherIsLoading(false);
      })
      .catch((err) => {
        setErrorTeacher(err.response.status);
        setTeacherIsLoading(false);
      });

    setTotalPaidOrderIsLoading(true);
    axios
      .get(URL_TOTALPAIDORDER, options)
      .then((res) => {
        setTotalPaidOrder(res.data);
        setTotalPaidOrderIsLoading(false);
      })
      .catch((err) => {
        setErrorTotalPaidOrder(err.response.status);
        setTotalPaidOrderIsLoading(false);
      });
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Dashboard Admin</h3>
        </Col>
      </Row>
      <Row>
        <DescriptionCard
          title="Total Participants"
          icon={<AccountCircleIcon className="dashboard__trend-icon dashboard-account-icon" />}
          loading={userIsLoading}
          total={user.length ? formatNumberWithCommas(user.length) : '0'}
          error={errorUser}
        />
        <DescriptionCard
          title="Total Courses"
          icon={<BookVariant className="dashboard__trend-icon dashboard-account-icon" />}
          loading={classIsLoading}
          total={course.length ? formatNumberWithCommas(course.length) : '0'}
          error={errorClass}
        />
        <DescriptionCard
          title="Total Instructors"
          icon={<AccountTieIcon className="dashboard__trend-icon dashboard-account-icon" />}
          loading={teacherIsLoading}
          total={teacher.length ? formatNumberWithCommas(teacher.length) : '0'}
          error={errorTeacher}
        />
        <DescriptionCard
          title="Total Revenue"
          icon={<MoneyIcon className="dashboard__trend-icon dashboard-account-icon" />}
          loading={totalPaidOrderIsLoading}
          total={totalPaidOrder.total_paid_order ? `$${formatNumberWithCommas(totalPaidOrder.total_paid_order)}` : '$0'}
          error={errorTotalPaidOrder}
        />

      </Row>
      <Row>
        <ChartRevenue />
        <EnrolledCart />
        <ChartUser />
      </Row>
    </Container>
  );
};

export default HomeAdmin;
