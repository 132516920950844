/* eslint-disable prefer-template */
/* eslint-disable max-len */

/* eslint-disable react/react-in-jsx-scope */
import
React,
{ useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const orderDetail = (history, info) => {
  history.push({
    pathname: `/admin/order-detail/${info.id}`,
    state: { data: info },
  });
};

const ListDataOrder = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Date',
        accessor: 'created_at',
        width: 110,
      },
      {
        Header: 'Order ID',
        accessor: 'order_id',
        width: 110,
      },
      {
        Header: 'Course',
        accessor: 'course',
        width: 110,
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: 110,
      },
      {
        Header: 'Total Price',
        accessor: 'total_price',
        width: 110,
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 110,
        disableSortBy: true,
      },
      {
        Header: 'Payment URL',
        accessor: 'payment_url',
        width: 110,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Action',
        accessor: 'action',
        width: 110,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    for (let i = 0; i < dataAPI.length; i += 1) {
      data.push({
        id: dataAPI[i].id,
        number: `${i + 1}`,
        created_at: dataAPI[i].created_at,
        course: dataAPI[i]?.item_list[0]?.course_detail?.title,
        order_id: dataAPI[i]?.order_id,
        name: dataAPI[i]?.user_detail?.name,
        payment_url: <a href={dataAPI[i].payment_url} target="_blank" rel="noreferrer">Click Here</a>,
        total_price: `$${dataAPI[i]?.total_price}`,
        status: dataAPI[i].status,
        action: [
          <Button
            key={dataAPI[i].id}
            color="outline-success"
            onClick={() => orderDetail(history, dataAPI[i])}
          >
            Detail
          </Button>,
        ],
      });
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataOrder;
