/* eslint-disable no-shadow */

import React from 'react';
import {
  Container,
} from 'reactstrap';
import CreateTeacherForm from './components/createTeacherForm';

const CreateInstructor = () => (
  <Container className="dashboard">
    <CreateTeacherForm />
  </Container>
);
export default CreateInstructor;
