/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Button } from 'reactstrap';
import { Spin, message } from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { AUTH_URL } from '../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN, LOCALSTORAGE_USERDETAIL } from '../../../../utils/Types';

const LogInForm = () => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const showPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('email', e.target.email.value);
    data.append('password', e.target.password.value);
    setLoading(true);
    axios.post(`${AUTH_URL}token/login/`, data)
      .then((res) => {
        // authentication
        const token = res.data.auth_token;

        localStorage.setItem(LOCALSTORAGE_TOKEN, token);
        // authorization
        axios.get(`${AUTH_URL}users/me/`, { headers: { Authorization: `Token ${token}` } })
          .then((userData) => {
            const userdetail = userData.data;
            if (userdetail.role === 'Admin') {
              messageApi.open({
                type: 'success',
                content: 'Login successfully authenticated',
              });
              setTimeout(() => {
                localStorage.setItem(LOCALSTORAGE_USERDETAIL, JSON.stringify(userData.data));
                history.push('/admin/dashboard');
                setLoading(false);
              }, 3000);
            } else {
              messageApi.open({
                type: 'error',
                content: 'You have no permission to access this website',
              });
              setLoading(false);
            }
          })
          .catch(() => {
            messageApi.open({
              type: 'error',
              content: 'Unable to log in with provided credentials.',
            });
            setLoading(false);
          });
      })
      .catch(() => {
        messageApi.open({
          type: 'error',
          content: 'Authentication failed. Please check your email or password',
        });
        setLoading(false);
      });
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      {contextHolder}
      <div className="form__form-group">
        <span className="form__form-group-label">Email</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            name="email"
            component="input"
            type="email"
            placeholder="email@example.com"
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Password</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <Field
            name="password"
            component="input"
            type={isPasswordShown ? 'text' : 'password'}
            placeholder="Password"
          />
          <button
            className={`form__form-group-button${isPasswordShown ? ' active' : ''}`}
            onClick={() => showPassword()}
            type="button"
            label="showPassword"
          ><EyeIcon />
          </button>
        </div>
        <div className="account__forgot-password">
          <a href="/reset-password">Forgot a password?</a>
        </div>
      </div>
      <Button type="submit" color="primary" className="account__btn mt-4">
        {isLoading ? <Spin size="small" /> : 'Login'}
      </Button>
    </form>
  );
};

export default reduxForm({
  form: 'log_in_form',
})(LogInForm);
