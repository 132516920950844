import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { LOCALSTORAGE_TOKEN, LOCALSTORAGE_USERDETAIL } from '../../../../utils/Types';
import { AUTH_URL } from '../../../../utils/EndPoints';
import NewPasswordForm from './components/newPasswordForm';

const NewPassword = () => {
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  const errorHandling = () => {
    setLoading(false);
    message.error('You do not have permission to access Asean Centre for Energy System');
  };

  const onSubmit = (e) => {
    const data = new FormData();
    data.append('email', e.email);
    data.append('password', e.password);
    setLoading(true);

    axios.post(`${AUTH_URL}token/login`, data)
      .then((res) => {
        // Authentication
        const token = res.data.auth_token;
        localStorage.setItem(LOCALSTORAGE_TOKEN, token);

        // Authorization
        axios.get(`${AUTH_URL}users/me`, { headers: { Authorization: `Token ${token}` } })
          .then((userData) => {
            const userdetail = userData.data;
            if (userdetail.role === 'Admin') {
              localStorage.setItem(LOCALSTORAGE_USERDETAIL, JSON.stringify(userData.data));
              history.push('/admin/dashboard');
            } else {
              errorHandling();
            }
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Input New Password</h2>
          <div className="login-form">
            {/*  */}
          </div>
          <NewPasswordForm isLoading={isLoading} onSubmit={(e) => onSubmit(e)} />
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
