/* eslint-disable no-shadow */

import React from 'react';
import {
  Container,
} from 'reactstrap';
import EditCategoryForm from './components/EditCourseTypeForm';

const EditCourseType = () => (
  <Container className="dashboard">
    <EditCategoryForm />
  </Container>
);
export default EditCourseType;
