/* eslint-disable no-shadow */
import React from 'react';
import {
  Container,
} from 'reactstrap';
import CreateTypeForm from './components/CreateTypeForm';

const CreateType = () => (
  <Container className="dashboard">
    <CreateTypeForm />
  </Container>
);
export default CreateType;
