/* eslint-disable indent */
/* eslint-disable no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container, Row,
} from 'reactstrap';
import { message } from 'antd';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/Types';
import { URL_ENROLLEDCOURSE } from '../../../../utils/EndPoints';
import ListDataEnroll from './components/ListData';
import EnrollmentListingTable from './components/EnrollmentListing';

const EnrollmentListing = () => {
  const [enrollment, setEnrollment] = useState([]);
  const listDataTable = ListDataEnroll(enrollment);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(URL_ENROLLEDCOURSE, options)
      .then((res) => {
        setEnrollment(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error('Something went wrong!');
      });
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <EnrollmentListingTable
          listDataTable={listDataTable}
          isLoading={isLoading}
        />
      </Row>
    </Container>
  );
};
export default EnrollmentListing;
