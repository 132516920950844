/* eslint-disable no-shadow */
import React from 'react';
import {
  Container,
} from 'reactstrap';
import EditCategoryForm from './components/EditCategoryForm';

const EditCategory = () => (
  <Container className="dashboard">
    <EditCategoryForm />
  </Container>
);
export default EditCategory;
