/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
// import SidebarCategory from './SidebarCategory';

const SidebarContent = ({ onClick }) => {
  const handleHideSidebar = () => {
    onClick();
  };

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarLink title="Dashboard" route="/admin/dashboard" icon="home" onClick={handleHideSidebar} />
        <SidebarLink title="Category" route="/admin/type" icon="highlight" onClick={handleHideSidebar} />
        <SidebarLink title="Topics" route="/admin/topic" icon="layers" onClick={handleHideSidebar} />
        <SidebarLink title="Courses" route="/admin/course" icon="book" onClick={handleHideSidebar} />
        <SidebarLink title="Instructors" route="/admin/instructor" icon="graduation-hat" onClick={handleHideSidebar} />
        <SidebarLink title="Users" route="/admin/user" icon="users" onClick={handleHideSidebar} />
        <SidebarLink title="Heroes" route="/admin/hero" icon="picture" onClick={handleHideSidebar} />
        <SidebarLink title="Partners" route="/admin/partners" icon="diamond" onClick={handleHideSidebar} />
        <SidebarLink title="Order" route="/admin/order" icon="cart" onClick={handleHideSidebar} />
        <SidebarLink title="Wishlist" route="/admin/wishlist" icon="heart" onClick={handleHideSidebar} />
        <SidebarLink title="Enrollment" route="/admin/enrollment" icon="store" onClick={handleHideSidebar} />
        <SidebarLink title="Review" route="/admin/review" icon="star" onClick={handleHideSidebar} />
        <SidebarLink title="Faq" route="/admin/faq" icon="question-circle" onClick={handleHideSidebar} />
        {/* <SidebarCategory title="Layout" icon="layers">
          <button type="button" className="sidebar__link" onClick={changeToLight}>
            <p className="sidebar__link-title">Light Theme</p>
          </button>
          <button type="button" className="sidebar__link" onClick={changeToDark}>
            <p className="sidebar__link-title">Dark Theme</p>
          </button>
        </SidebarCategory> */}
      </ul>
      {/* <ul className="sidebar__block">
        <SidebarCategory title="Example Pages" icon="diamond">
          <SidebarLink title="Page one" route="/pages/one" onClick={handleHideSidebar} />
          <SidebarLink title="Page two" route="/pages/two" onClick={handleHideSidebar} />
        </SidebarCategory>
      </ul> */}
    </div>
  );
};

SidebarContent.propTypes = {
  // changeToDark: PropTypes.func.isRequired,
  // changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SidebarContent;
