/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Progress,
} from 'reactstrap';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import {
  LOCALSTORAGE_TOKEN,
} from '../../../../../utils/Types';
import renderFileInputField from '../../../../../shared/components/form/FileInput';
import { URL_INSTRUCTOR } from '../../../../../utils/EndPoints';
import renderSelectField from '../../../../../shared/components/form/Select';

const CreateTeacherForm = () => {
  const history = useHistory();
  const [progress, setProgress] = useState(null);
  const [image, setphoto] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedLabel, setSelectedLabel] = useState();

  const countries = [
    { value: 'AF', label: 'Afghanistan' },
    { value: 'AL', label: 'Albania' },
    { value: 'DZ', label: 'Algeria' },
    { value: 'AD', label: 'Andorra' },
    { value: 'AO', label: 'Angola' },
    { value: 'AG', label: 'Antigua and Barbuda' },
    { value: 'AR', label: 'Argentina' },
    { value: 'AM', label: 'Armenia' },
    { value: 'AU', label: 'Australia' },
    { value: 'AT', label: 'Austria' },
    { value: 'AZ', label: 'Azerbaijan' },
    { value: 'BS', label: 'Bahamas' },
    { value: 'BH', label: 'Bahrain' },
    { value: 'BD', label: 'Bangladesh' },
    { value: 'BB', label: 'Barbados' },
    { value: 'BY', label: 'Belarus' },
    { value: 'BE', label: 'Belgium' },
    { value: 'BZ', label: 'Belize' },
    { value: 'BJ', label: 'Benin' },
    { value: 'BT', label: 'Bhutan' },
    { value: 'BO', label: 'Bolivia' },
    { value: 'BA', label: 'Bosnia and Herzegovina' },
    { value: 'BW', label: 'Botswana' },
    { value: 'BR', label: 'Brazil' },
    { value: 'BN', label: 'Brunei Darussalam' },
    { value: 'BG', label: 'Bulgaria' },
    { value: 'BF', label: 'Burkina Faso' },
    { value: 'BI', label: 'Burundi' },
    { value: 'CV', label: 'Cape Verde' },
    { value: 'KH', label: 'Cambodia' },
    { value: 'CM', label: 'Cameroon' },
    { value: 'CA', label: 'Canada' },
    { value: 'CF', label: 'Central African Republic' },
    { value: 'TD', label: 'Chad' },
    { value: 'CL', label: 'Chile' },
    { value: 'CN', label: 'China' },
    { value: 'CO', label: 'Colombia' },
    { value: 'KM', label: 'Comoros' },
    { value: 'CD', label: 'Congo (Democratic Republic)' },
    { value: 'CG', label: 'Congo (Republic)' },
    { value: 'CR', label: 'Costa Rica' },
    { value: 'CI', label: "Cote d'Ivoire" },
    { value: 'HR', label: 'Croatia' },
    { value: 'CU', label: 'Cuba' },
    { value: 'CY', label: 'Cyprus' },
    { value: 'CZ', label: 'Czech Republic' },
    { value: 'DK', label: 'Denmark' },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'DM', label: 'Dominica' },
    { value: 'DO', label: 'Dominican Republic' },
    { value: 'EC', label: 'Ecuador' },
    { value: 'EG', label: 'Egypt' },
    { value: 'SV', label: 'El Salvador' },
    { value: 'GQ', label: 'Equatorial Guinea' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'EE', label: 'Estonia' },
    { value: 'SZ', label: 'Eswatini' },
    { value: 'ET', label: 'Ethiopia' },
    { value: 'FJ', label: 'Fiji' },
    { value: 'FI', label: 'Finland' },
    { value: 'FR', label: 'France' },
    { value: 'GA', label: 'Gabon' },
    { value: 'GM', label: 'Gambia' },
    { value: 'GE', label: 'Georgia' },
    { value: 'DE', label: 'Germany' },
    { value: 'GH', label: 'Ghana' },
    { value: 'GR', label: 'Greece' },
    { value: 'GD', label: 'Grenada' },
    { value: 'GT', label: 'Guatemala' },
    { value: 'GN', label: 'Guinea' },
    { value: 'GW', label: 'Guinea-Bissau' },
    { value: 'GY', label: 'Guyana' },
    { value: 'HT', label: 'Haiti' },
    { value: 'HN', label: 'Honduras' },
    { value: 'HU', label: 'Hungary' },
    { value: 'IS', label: 'Iceland' },
    { value: 'IN', label: 'India' },
    { value: 'ID', label: 'Indonesia' },
    { value: 'IR', label: 'Iran' },
    { value: 'IQ', label: 'Iraq' },
    { value: 'IE', label: 'Ireland' },
    { value: 'IL', label: 'Israel' },
    { value: 'IT', label: 'Italy' },
    { value: 'JM', label: 'Jamaica' },
    { value: 'JP', label: 'Japan' },
    { value: 'JO', label: 'Jordan' },
    { value: 'KZ', label: 'Kazakhstan' },
    { value: 'KE', label: 'Kenya' },
    { value: 'KI', label: 'Kiribati' },
    { value: 'KP', label: 'Korea (North)' },
    { value: 'KR', label: 'Korea (South)' },
    { value: 'XK', label: 'Kosovo' },
    { value: 'KW', label: 'Kuwait' },
    { value: 'KG', label: 'Kyrgyzstan' },
    { value: 'LA', label: 'Laos' },
    { value: 'LV', label: 'Latvia' },
    { value: 'LB', label: 'Lebanon' },
    { value: 'LS', label: 'Lesotho' },
    { value: 'LR', label: 'Liberia' },
    { value: 'LY', label: 'Libya' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'LT', label: 'Lithuania' },
    { value: 'LU', label: 'Luxembourg' },
    { value: 'MG', label: 'Madagascar' },
    { value: 'MW', label: 'Malawi' },
    { value: 'MY', label: 'Malaysia' },
    { value: 'MV', label: 'Maldives' },
    { value: 'ML', label: 'Mali' },
    { value: 'MT', label: 'Malta' },
    { value: 'MH', label: 'Marshall Islands' },
    { value: 'MR', label: 'Mauritania' },
    { value: 'MU', label: 'Mauritius' },
    { value: 'MX', label: 'Mexico' },
    { value: 'FM', label: 'Micronesia' },
    { value: 'MD', label: 'Moldova' },
    { value: 'MC', label: 'Monaco' },
    { value: 'MN', label: 'Mongolia' },
    { value: 'ME', label: 'Montenegro' },
    { value: 'MA', label: 'Morocco' },
    { value: 'MZ', label: 'Mozambique' },
    { value: 'MM', label: 'Myanmar' },
    { value: 'NA', label: 'Namibia' },
    { value: 'NR', label: 'Nauru' },
    { value: 'NP', label: 'Nepal' },
    { value: 'NL', label: 'Netherlands' },
    { value: 'NZ', label: 'New Zealand' },
    { value: 'NI', label: 'Nicaragua' },
    { value: 'NE', label: 'Niger' },
    { value: 'NG', label: 'Nigeria' },
    { value: 'MK', label: 'North Macedonia' },
    { value: 'NO', label: 'Norway' },
    { value: 'OM', label: 'Oman' },
    { value: 'PK', label: 'Pakistan' },
    { value: 'PW', label: 'Palau' },
    { value: 'PS', label: 'Palestine' },
    { value: 'PA', label: 'Panama' },
    { value: 'PG', label: 'Papua New Guinea' },
    { value: 'PY', label: 'Paraguay' },
    { value: 'PE', label: 'Peru' },
    { value: 'PH', label: 'Philippines' },
    { value: 'PL', label: 'Poland' },
    { value: 'PT', label: 'Portugal' },
    { value: 'QA', label: 'Qatar' },
    { value: 'RO', label: 'Romania' },
    { value: 'RU', label: 'Russia' },
    { value: 'RW', label: 'Rwanda' },
    { value: 'KN', label: 'Saint Kitts and Nevis' },
    { value: 'LC', label: 'Saint Lucia' },
    { value: 'VC', label: 'Saint Vincent and the Grenadines' },
    { value: 'WS', label: 'Samoa' },
    { value: 'SM', label: 'San Marino' },
    { value: 'ST', label: 'Sao Tome and Principe' },
    { value: 'SA', label: 'Saudi Arabia' },
    { value: 'SN', label: 'Senegal' },
    { value: 'RS', label: 'Serbia' },
    { value: 'SC', label: 'Seychelles' },
    { value: 'SL', label: 'Sierra Leone' },
    { value: 'SG', label: 'Singapore' },
    { value: 'SK', label: 'Slovakia' },
    { value: 'SI', label: 'Slovenia' },
    { value: 'SB', label: 'Solomon Islands' },
    { value: 'SO', label: 'Somalia' },
    { value: 'ZA', label: 'South Africa' },
    { value: 'SS', label: 'South Sudan' },
    { value: 'ES', label: 'Spain' },
    { value: 'LK', label: 'Sri Lanka' },
    { value: 'SD', label: 'Sudan' },
    { value: 'SR', label: 'Suriname' },
    { value: 'SE', label: 'Sweden' },
    { value: 'CH', label: 'Switzerland' },
    { value: 'SY', label: 'Syria' },
    { value: 'TJ', label: 'Tajikistan' },
    { value: 'TZ', label: 'Tanzania' },
    { value: 'TH', label: 'Thailand' },
    { value: 'TL', label: 'Timor-Leste' },
    { value: 'TG', label: 'Togo' },
    { value: 'TO', label: 'Tonga' },
    { value: 'TT', label: 'Trinidad and Tobago' },
    { value: 'TN', label: 'Tunisia' },
    { value: 'TR', label: 'Turkey' },
    { value: 'TM', label: 'Turkmenistan' },
    { value: 'TV', label: 'Tuvalu' },
    { value: 'UG', label: 'Uganda' },
    { value: 'UA', label: 'Ukraine' },
    { value: 'AE', label: 'United Arab Emirates' },
    { value: 'GB', label: 'United Kingdom' },
    { value: 'US', label: 'United States' },
    { value: 'UY', label: 'Uruguay' },
    { value: 'UZ', label: 'Uzbekistan' },
    { value: 'VU', label: 'Vanuatu' },
    { value: 'VA', label: 'Vatican City' },
    { value: 'VE', label: 'Venezuela' },
    { value: 'VN', label: 'Vietnam' },
    { value: 'YE', label: 'Yemen' },
    { value: 'ZM', label: 'Zambia' },
    { value: 'ZW', label: 'Zimbabwe' },
  ];

  const handleChange = (event) => {
    const selectedOption = countries.find(
      (option) => option.value === event.value,
    );
    setSelectedLabel(selectedOption.label);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    if (e.target.image.files[0]) {
      data.append('image', e.target.image.files[0]);
    }
    data.append('name', e.target.name.value);
    data.append('position', e.target.position.value);
    data.append('organization', e.target.organization.value);
    data.append('gender', e.target.gender.value);
    data.append('country', selectedLabel);
    data.append('profile', e.target.profile.value);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };
    axios
      .post(URL_INSTRUCTOR, data, options)
      .then(() => {
        messageApi.success('New Instructor Successfully Created');
        history.push('/admin/instructor');
      }).catch(() => {
        messageApi.error('New Instructor Failed Created');
      });
  };

  const imageHandler = () => {
    if (image) {
      return image;
    }
    return '/img/no_image.png';
  };

  const inputImageHandler = (e) => {
    setphoto(URL.createObjectURL(e.file));
    const conditions = ['image/png', 'image/jpg', 'image/jpeg'];

    if (!conditions.some((el) => e.file.type.includes(el))) {
      messageApi.open({
        type: 'error',
        content: 'Only image file types allowed',
      });
    }
  };

  return (
    <Col md={12} lg={12}>
      {contextHolder}
      <ButtonToolbar className="form__button-toolbar">
        <Button size="sm" color="danger" onClick={history.goBack}>
          Back
        </Button>
      </ButtonToolbar>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <form className="form" onSubmit={onSubmit}>
            <div className="col-lg-12 col-md-12">
              <img src={imageHandler()} alt="" className="testimonial-image" />
              <span className="form__form-group-label">Photo</span>
              <div className="form__form-group">
                <div className="form__form-group-field mb-0-label">
                  <Field
                    name="image"
                    component={renderFileInputField}
                    required
                    onChange={(e) => { inputImageHandler(e); }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Full Name</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="name"
                    component="input"
                    type="text"
                    placeholder="Enter Full Name"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Position</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="position"
                    component="input"
                    type="text"
                    placeholder="Enter Position"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <span className="form__form-group-label">Organization</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="organization"
                    component="input"
                    type="text"
                    placeholder="Enter Organization"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <span className="form__form-group-label">Country</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="country"
                    component={renderSelectField}
                    type="text"
                    options={countries}
                    placeholder="Select Country"
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <span className="form__form-group-label">Gender</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="gender"
                    component={renderSelectField}
                    type="text"
                    options={[
                      { value: 'Male', label: 'Male' },
                      { value: 'Female', label: 'Female' },
                    ]}
                    placeholder="Select Gender"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">Profile</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="profile"
                    component="textarea"
                    type="text"
                    placeholder="Fill in with your background"
                    required
                  />
                </div>
              </div>
            </div>

            {progress !== null && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                type="submit"
              >
                Submit
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'CreateTeacher_form', // a unique identifier for this form
})(CreateTeacherForm);
