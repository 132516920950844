/* eslint-disable no-shadow */

import React from 'react';
import {
  Container,
  ButtonToolbar,
  Button,
  CardBody,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import CreateEnrollForm from './components/EnrollForm';

const CreateEnroll = () => {
  const history = useHistory();

  return (
    <Container className="dashboard">
      <ButtonToolbar className="form__button-toolbar">
        <Button size="sm" color="danger" onClick={history.goBack}>
          Back
        </Button>
      </ButtonToolbar>
      <CardBody>
        <CreateEnrollForm />
      </CardBody>
    </Container>
  );
};

export default CreateEnroll;
