/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
import
React,
{ useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const PhotoFormatter = (value) => (
  <div>
    <img
      src={value}
      alt=""
      style={{
        width: '50px', height: 'auto', marginRight: '6px', borderRadius: '5px',
      }}
    />
  </div>
);

const categoryDetail = (history, info) => {
  history.push({
    pathname: `/admin/new-type-detail/${info.id}`,
    state: { data: info },
  });
};

const ListDataELibrary = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: 110,
      },
      {
        Header: 'Thumbnail',
        accessor: 'thumbnail',
        disableGlobalFilter: true,
        width: 110,
      },
      {
        Header: 'Thumbnail',
        accessor: 'photo',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Action',
        accessor: 'status',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    for (let i = 0; i < dataAPI.length; i += 1) {
      data.push({
        id: dataAPI[i]?.id ?? '',
        number: `${i + 1}`,
        name: dataAPI[i]?.name ?? '',
        thumbnail: <a href={dataAPI[i]?.thumbnail} target="_blank" rel="noreferrer" style={{ marginRight: '6px' }}>{dataAPI[i]?.thumbnail?.slice(64)}</a>,
        photo: PhotoFormatter(`${dataAPI[i]?.thumbnail}`),
        status: [
          // eslint-disable-next-line no-loop-func
          // eslint-disable-next-line max-len
          (<Button key={dataAPI[i]?.id} color="outline-success" onClick={() => categoryDetail(history, dataAPI[i])}>Detail</Button>),
        ],
      });
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataELibrary;
