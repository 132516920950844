/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Progress,
} from 'reactstrap';
import { URL_INSTRUCTOR, URL_INSTRUCTORMAPPING, URL_INSTRUCTOR_BY_IDCOURSE } from '../../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';
import renderSelectField from '../../../../../shared/components/form/Select';

const CreateInstructorForm = () => {
  const { id } = useParams();
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [instructors, setInstructors] = useState([]);
  const [existingInstructors, setExistingInstructors] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };

    axios.get(`${URL_INSTRUCTOR}`, options)
      .then((res) => {
        const instructorList = res.data.map((item) => ({
          value: `${item.id}`,
          label: `${item.name}`,
        }));
        setInstructors(instructorList);
      })
      .catch(() => {
        message.error('Something went wrong!');
      });

    axios.get(`${URL_INSTRUCTOR_BY_IDCOURSE}${id}`, options)
      .then((res) => {
        setExistingInstructors(res.data);
      })
      .catch(() => {
        message.error('Something went wrong!');
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    const selectedInstructorId = parseInt(e.target.elements.userId.value, 10);

    const isAlreadyAssigned = existingInstructors.some((existingInstructor) => existingInstructor.instructorId === selectedInstructorId);
    if (isAlreadyAssigned) {
      message.error('Instructor already assigned to this course.');
      return;
    }

    const data = new FormData();
    data.append('instructorId', selectedInstructorId);
    data.append('courseId', id);
    data.append('instructor_detail', 'tes');

    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (pro) => {
        setProgress(Math.round((100 * pro.loaded) / pro.total));
      },
    };

    setLoading(true);
    axios.post(`${URL_INSTRUCTORMAPPING}`, data, options)
      .then(() => {
        messageApi.success('New Instructor Successfully Created');
        window.location.reload();
      })
      .catch(() => {
        messageApi.error('New Instructor Failed Created');
        setLoading(false);
      });
  };

  return (
    <Col md={12} lg={12}>
      {contextHolder}
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <form className="form" onSubmit={onSubmit}>
            <span className="form__form-group-label">Instructor</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="userId"
                  component={renderSelectField}
                  type="text"
                  options={instructors}
                  placeholder="Select Instructor"
                  required
                />
              </div>
            </div>
            {/* <span className="form__form-group-label">Position</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="position"
                  type="text"
                />
              </div>
            </div> */}

            {loading && (
              <Col md={12} lg={12}>
                <Progress animated value={progress}>{progress}%</Progress>
              </Col>
            )}
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">
                Save
              </Button>
            </ButtonToolbar>
          </form>
          <div><a href="/admin/new-instructor" style={{ color: 'blue' }}>Create New Instructor</a></div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'instructor_create',
})(CreateInstructorForm);
