/* eslint-disable func-names */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect, useRef } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Progress,
} from 'reactstrap';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import {
  LOCALSTORAGE_TOKEN,
  LOCALSTORAGE_USERDETAIL,
} from '../../../../../utils/Types';
import renderCheckBoxField from '../../../../../shared/components/form/CheckBox';
import renderFileInputField from '../../../../../shared/components/form/FileInput';
import {
  URL_CATEGORYCOURSE,
  URL_COURSE,
  URL_SUBCATEGORYCOURSEBYCATEGORY,
} from '../../../../../utils/EndPoints';
import renderSelectField from '../../../../../shared/components/form/Select';

const CreateCourseForm = () => {
  const history = useHistory();
  const [progress, setProgress] = useState(null);
  const [categoriesImg, setCategoriesImg] = useState(null);
  const [coursesImg, setCoursesImg] = useState(null);
  const [coursesSmallImg, setCoursesSmallImg] = useState(null);
  const [coursePreview, setCoursePreview] = useState(null);
  const editorRef = useRef(null);
  const [messageApi, contextHolder] = message.useMessage();

  // list select option
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  // loading
  const [loading, setLoading] = useState(false);

  // Tinymce Editor
  const [content, setContents] = useState('');
  const handleEditorChange = (contents) => {
    setContents(contents);
  };

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(URL_CATEGORYCOURSE, options)
      .then((res) => {
        setCategories(
          res.data.map((category) => ({
            value: `${category.id}`,
            label: category.name,
          })),
        );
      })
      .catch(() => {
        message.error('Something went wrong!');
      });
    axios
      .get(
        `${URL_SUBCATEGORYCOURSEBYCATEGORY}`,
        options,
      )
      .then((res1) => {
        setSubcategories(
          res1.data.map((type) => ({
            value: `${type.id}`,
            label: type.name,
          })),
        );
      })
      .catch(() => {
        message.error('Something went wrong!');
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const userDetail = JSON.parse(
      localStorage.getItem(LOCALSTORAGE_USERDETAIL),
    );
    if (!e.target.categories_img.files[0] || !e.target.courses_img.files[0] || !e.target.courses_small_img.files[0] || !e.target.course_preview_video.files[0] || !e.target.category.value || !e.target.type.value) {
      messageApi.open({
        type: 'error',
        content: 'Please fill out all required fields.',
      });
      return;
    }
    const data1 = new FormData(e.target);
    data1.append('userId', userDetail.id);
    data1.append('title', e.target.title.value);
    data1.append('price', e.target.price.value);
    // data.append('duration', e.target.duration.value);
    // data.append('lesson_hours', e.target.lesson_hours.value);
    if (e.target.categories_img.files[0]) {
      data1.append('categories_img', e.target.categories_img.files[0]);
    }
    if (e.target.courses_img.files[0]) {
      data1.append('courses_img', e.target.courses_img.files[0]);
    }
    if (e.target.courses_small_img.files[0]) {
      data1.append(
        'courses_small_img',
        e.target.courses_small_img.files[0],
      );
    }
    if (e.target.course_preview_video.files[0]) {
      data1.append(
        'course_preview_video',
        e.target.course_preview_video.files[0],
      );
    }
    data1.append('categoryId', e.target.category.value);
    data1.append('course_code', e.target.course_code.value);
    data1.append('typeId', e.target.type.value);
    data1.append('overview', content);
    data1.append('isPublish', e.target.isPublish.checked);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data2) => {
        setProgress(Math.round((100 * data2.loaded) / data2.total));
      },
    };

    setLoading(true);
    axios
      .post(URL_COURSE, data1, options)
      .then(() => {
        messageApi.success('New Course Successfully Created');
        history.push('/admin/course');
        setLoading(false);
      })
      .catch(() => {
        messageApi.error('New Course Failed Created');
        setLoading(false);
      });
  };

  const categoriesImageHandler = () => {
    if (categoriesImg) {
      return categoriesImg;
    }
    return '/img/no_image.png';
  };

  const coursesImageHandler = () => {
    if (coursesImg) {
      return coursesImg;
    }
    return '/img/no_image.png';
  };

  const coursesSmallImageHandler = () => {
    if (coursesSmallImg) {
      return coursesSmallImg;
    }
    return '/img/no_image.png';
  };

  const inputCategoriesImageHandler = (e) => {
    setCategoriesImg(URL.createObjectURL(e.file));
    const conditions = ['image/png', 'image/jpg', 'image/jpeg'];

    if (!conditions.some((el) => e.file.type.includes(el))) {
      messageApi.open({
        type: 'error',
        content: 'Only image file types allowed',
      });
    }
  };

  const inputCoursesImageHandler = (e) => {
    setCoursesImg(URL.createObjectURL(e.file));
    const conditions = ['image/png', 'image/jpg', 'image/jpeg'];

    if (!conditions.some((el) => e.file.type.includes(el))) {
      messageApi.open({
        type: 'error',
        content: 'Only image file types allowed',
      });
    }
  };

  const inputCoursesSmallImageHandler = (e) => {
    setCoursesSmallImg(URL.createObjectURL(e.file));
    const conditions = ['image/png', 'image/jpg', 'image/jpeg'];

    if (!conditions.some((el) => e.file.type.includes(el))) {
      messageApi.open({
        type: 'error',
        content: 'Only image file types allowed',
      });
    }
  };

  const inputVideoPreviewHandler = (e) => {
    setCoursePreview(URL.createObjectURL(e.file));
    if (!e.file.type.includes('video')) {
      messageApi.open({
        type: 'error',
        content: 'Only video file types allowed',
      });
    }
  };

  const coursesVideoPreviewHandler = () => {
    if (coursePreview) {
      return coursePreview;
    }
    return '/img/no_image.png';
  };

  return (
    <Col md={12} lg={12}>
      {contextHolder}
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Create New Course</h5>
          </div>
          <form className="form" onSubmit={onSubmit}>
            <span className="form__form-group-label">Title</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="title"
                  component="input"
                  type="text"
                  placeholder="Input Course Title"
                  required
                />
              </div>
            </div>

            <div className="form__form-group">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <span className="form__form-group-label">Topic</span>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="category"
                        component={renderSelectField}
                        type="text"
                        options={categories}
                        placeholder="Select Topic"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-12">
                  <span className="form__form-group-label">Category</span>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="type"
                        component={renderSelectField}
                        type="text"
                        options={subcategories}
                        placeholder="Select Category"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12">
                  <span className="form__form-group-label">Course Code</span>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="course_code"
                        component="input"
                        type="text"
                        placeholder="Course Code, example: AB"
                        required
                        maxLength={2}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-12">
                <img src={categoriesImageHandler()} alt="" className="testimonial-image" />
                <span className="form__form-group-label">Category Image (750 X 500)</span>
                <div className="form__form-group">
                  <div className="form__form-group-field mb-0-label">
                    <Field
                      name="categories_img"
                      component={renderFileInputField}
                      required
                      onChange={(e) => { inputCategoriesImageHandler(e); }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <img src={coursesImageHandler()} alt="" className="testimonial-image" />
                <span className="form__form-group-label">Course Image (1920 x 500)</span>
                <div className="form__form-group">
                  <div className="form__form-group-field mb-0-label">
                    <Field
                      name="courses_img"
                      component={renderFileInputField}
                      required
                      onChange={(e) => { inputCoursesImageHandler(e); }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <img src={coursesSmallImageHandler()} alt="" className="testimonial-image" />
                <span className="form__form-group-label">Small Course Image (380 X 380)</span>
                <div className="form__form-group">
                  <div className="form__form-group-field mb-0-label">
                    <Field
                      name="courses_small_img"
                      component={renderFileInputField}
                      required
                      onChange={(e) => { inputCoursesSmallImageHandler(e); }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <iframe
                  className="testimonial-image"
                  width="420"
                  height="280"
                  src={coursesVideoPreviewHandler()}
                />
                <span className="form__form-group-label">Video Preview</span>
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Field
                      name="course_preview_video"
                      component={renderFileInputField}
                      onChange={(e) => {
                        inputVideoPreviewHandler(e);
                      }}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <span className="form__form-group-label">Price (Dollar)</span>
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Field
                      name="price"
                      component="input"
                      min={0}
                      type="number"
                      placeholder="Price (ex: 50)"
                      required
                    />
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-4 col-md-12">
                <span className="form__form-group-label">Duration (Minute)</span>
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Field
                      name="duration"
                      component="input"
                      min={0}
                      type="number"
                      placeholder="Duration Minute (ex: 120)"
                      required
                    />
                  </div>
                </div>
              </div> */}

              {/* <div className="col-lg-4 col-md-12">
                <span className="form__form-group-label">Learning Hours (Hours)</span>
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Field
                      name="lesson_hours"
                      component="input"
                      min={0}
                      type="number"
                      placeholder="Learning Hours (ex: 10)"
                      required
                    />
                  </div>
                </div>
              </div> */}
            </div>

            <span className="form__form-group-label">Class Description</span>
            <div className="form__form-group">
              <div className="form__form-group-field w-100-role-application">
                <input
                  id="my-file"
                  type="file"
                  name="my-file"
                  style={{ display: 'none' }}
                />
                <Editor
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  apiKey="kdsr6jcmxjffjg6z20pb4vtbg0fegwqdqy6ltsxoahuo3dvg"
                  init={{
                    skin: 'snow',
                    icons: 'thin',
                    height: 500,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen textcolor ',
                      'insertdatetime media table paste code help wordcount',
                      'image code',
                    ],
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    textcolor_rows: '4',
                    file_picker_callback(callback, value, meta) {
                      if (meta.filetype === 'image') {
                        const input = document.getElementById('my-file');
                        input.click();
                        input.onchange = function () {
                          const file = input.files[0];
                          const reader = new FileReader();
                          reader.onload = function (e) {
                            callback(e.target.result, {
                              alt: file.name,
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                      }
                    },

                    content_style:
                      "@import url('http://fonts.gstatic.com/s/nunito/v7/1TiHc9yag0wq3lDO9cw0vpBw1xU1rKptJj_0jans920.woff2');",
                    font_formats: 'Nunito',

                    toolbar:
                      'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
                  }}
                  onEditorChange={handleEditorChange}
                  outputFormat="html"
                />
              </div>
            </div>

            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="isPublish"
                  component={renderCheckBoxField}
                  label="Is Course ready to be released?"
                />
              </div>
            </div>

            {loading && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'create_course', // a unique identifier for this form
})(CreateCourseForm);
