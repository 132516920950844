/* eslint-disable no-shadow */
import React from 'react';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import EditWishlistForm from './components/EditWishlistForm';

const WishlistDetail = () => {
  const history = useHistory();

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <ButtonToolbar className="form__button-toolbar">
          <Button size="sm" color="danger" onClick={history.goBack}>
            Back
          </Button>
        </ButtonToolbar>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">WISHLIST DETAIL</h5>
          </div>
          <div className="tabs tabs--justify tabs--bordered-bottom">
            <EditWishlistForm />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
export default WishlistDetail;
