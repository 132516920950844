/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress,
} from 'reactstrap';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import { message } from 'antd';
import { URL_USERS } from '../../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';
import renderFileInputField from '../../../../../shared/components/form/FileInput';
import renderSelectField from '../../../../../shared/components/form/Select';

const EditUserForm = (stateOri) => {
  const params = useParams();
  const [progress, setProgress] = useState(null);
  const [isEditable, setEditable] = useState(false);
  const history = useHistory();
  const { location } = history;
  const { state } = location;
  const { initialize } = stateOri;
  const { data } = state;
  const [modal, setModal] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(data?.country);

  const countries = [
    { value: 'BN', label: 'Brunei Darussalam' },
    { value: 'KH', label: 'Cambodia' },
    { value: 'ID', label: 'Indonesia' },
    { value: 'LA', label: 'Laos' },
    { value: 'MY', label: 'Malaysia' },
    { value: 'MM', label: 'Myanmar' },
    { value: 'PH', label: 'Philippines' },
    { value: 'SG', label: 'Singapore' },
    { value: 'TH', label: 'Thailand' },
    { value: 'VN', label: 'VietNam' },
  ];

  const handleChange = (event) => {
    const selectedOption = countries.find(
      (option) => option.value === event.value,
    );
    setSelectedLabel(selectedOption.label);
  };
  const toggle = () => {
    setModal(!modal);
  };
  const [file, setFile] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initialize({
      ...data,
      country: { value: data?.country_code, label: data?.country },
      role: { value: data?.role, label: data?.role },
    });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('name', e.target.name.value);
    data.append('email', e.target.email.value);
    data.append('phone', e.target.phone.value);
    data.append('job', e.target.job.value);
    data.append('role', e.target.role.value);
    data.append('street_address', e.target.street_address.value);
    data.append('city', e.target.city.value);
    data.append('country', selectedLabel);
    data.append('country_code', e.target.country.value);
    data.append('postal_code', e.target.postal_code.value);
    data.append('organization', e.target.organization.value);
    data.append('about', e.target.about.value);
    if (e.target.photo.files[0]) {
      data.append('photo', e.target.photo.files[0]);
    }
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };
    setLoading(true);
    axios
      .patch(`${URL_USERS + params.id}/`, data, options)
      .then(() => {
        messageApi.success('User Failed Updated');
        history.push('/admin/user');
        setLoading(false);
      })
      .catch(() => {
        messageApi.error('User Failed Updated');
        setLoading(false);
      });
  };

  const imageHandler = () => {
    if (file) {
      return file;
    } if (data.photo) {
      return data.photo;
    }
    return '/img/no_image.png';
  };

  const inputImageHandler = (e) => {
    setFile(URL.createObjectURL(e.file));
    const conditions = ['image/png', 'image/jpg', 'image/jpeg'];

    if (!conditions.some((el) => e.file.type.includes(el))) {
      messageApi.open({
        type: 'error',
        content: 'Only image file types allowed',
      });
    }
  };

  return (
    <Col md={12} lg={12}>
      {contextHolder}
      <ButtonToolbar className="form__button-toolbar">
        <Button size="sm" color="danger" onClick={history.goBack}>
          Back
        </Button>
      </ButtonToolbar>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">User Detail</h5>
          </div>
          <form className="form" onSubmit={onSubmit}>
            <img src={imageHandler()} alt="" className="testimonial-image" />
            <span className="form__form-group-label">User Photo</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="photo"
                  component={renderFileInputField}
                  accept="image/x-png,image/jpeg"
                  onChange={(e) => { inputImageHandler(e); }}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <span className="form__form-group-label">Full Name</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="name"
                    component="input"
                    type="text"
                    placeholder="Name"
                    required
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <span className="form__form-group-label">Email</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="email"
                    component="input"
                    type="email"
                    placeholder="Enter Email"
                    required
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <span className="form__form-group-label">Date</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="created_at"
                    disabled
                    component="input"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <span className="form__form-group-label">Phone</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="phone"
                    component="input"
                    type="text"
                    placeholder="Enter Phone Number"
                    required
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <span className="form__form-group-label">Role</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="role"
                    component={renderSelectField}
                    type="text"
                    options={[
                      { value: 'User', label: 'User' },
                      { value: 'Admin', label: 'Admin' },
                    ]}
                    placeholder="Select Role"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <span className="form__form-group-label">Job</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="job"
                    component="input"
                    type="text"
                    placeholder="Enter Job"
                    required
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">Organization</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="organization"
                    component="input"
                    type="text"
                    placeholder="Enter Organization"
                    required
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">Street Address</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="street_address"
                    component="textarea"
                    type="text"
                    placeholder="Enter Street Address"
                    required
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <span className="form__form-group-label">City</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="city"
                    component="input"
                    type="text"
                    placeholder="Enter City"
                    required
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <span className="form__form-group-label">Country</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="country"
                    component={renderSelectField}
                    type="text"
                    options={countries}
                    placeholder="Select Country"
                    required
                    disabled={!isEditable}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <span className="form__form-group-label">Postal Code</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="postal_code"
                    component="input"
                    type="text"
                    placeholder="Enter Postal Code"
                    required
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <span className="form__form-group-label">About</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="about"
                    component="textarea"
                    type="text"
                    placeholder="About User"
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div>
            {loading && (
            <Col md={12} lg={12}>
              <Progress animated value={progress}>{progress}%</Progress>
            </Col>
            )}
            <ButtonToolbar className="form__button-toolbar">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setEditable(!isEditable)}
                color={isEditable ? 'danger' : 'primary'}
              >
                {' '}
                {!isEditable ? 'Change' : 'Cancel'}
              </button>
              {isEditable && (
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  {' '}
                  Change
                </button>
              )}
              {isEditable === false && (
                <button
                  type="button"
                  size="sm"
                  onClick={() => toggle()}
                  className="btn btn-danger"
                >
                  Delete
                </button>
              )}
              <Modal
                toggle={toggle}
                isOpen={modal}
                backdrop={false}
              >
                <ModalHeader toggle={toggle}>
                  Delete User
                </ModalHeader>
                <ModalBody>
                  Are you sure you want to Delete this User?
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                      const options = {
                        headers: {
                          'Content-Type':
                            'application/json',
                          Authorization: `Token ${token}`,
                        },
                      };
                      axios
                        .delete(
                          `${URL_USERS}${data.id}/`,
                          options,
                        )
                        .then(() => {
                          history.push('/admin/user');
                        })
                        .catch(() => {
                          message.error('Something went wrong!');
                        });
                    }}
                  >
                    Delete
                  </Button>
                </ModalFooter>
              </Modal>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'user_edit',
})(EditUserForm);
